import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js";
import { CapsuleCollider, RigidBody, useRapier } from "@react-three/rapier";
import { useEffect, useRef, useState } from "react";
import { usePersonControls } from "../../utils2/hooks";
import { useFrame } from "@react-three/fiber";
import { isMobile, isBrowser } from "react-device-detect";
import useStore from ".././../utils2/3dstore";
import useUIStore from "../../utils2/uistore";
import useDeviceDetect from "../../utils2/useDeviceDetect";
import useGameStore from "../Game/utils/gameStore";

//calibrate as per requirement

const entryAnimationDuration = 6000;
const teleportingAnimationDuration = 280;
const MOVE_SPEED = 300;
const direction = new THREE.Vector3();
const frontVector = new THREE.Vector3();
const sideVector = new THREE.Vector3();
let requiredDistance;
let cameraRequiredDistance;
let playerRequiredDistance;
export const Player = () => {
  const isTouchDevice = useDeviceDetect();
  const {
    hitPoint,
    isDoubleClick,
    setDoubleClick,
    isMovingWithJoystick,
    joystickFoward,
    joystickTurn,
    isProductClick,
    setProductClick,
    isEntryAnimationDone,
    setDoneEntryAnimation,
    setRotateCamera,
    joystickSpeed,
    setPanEnabled,
    setShowForm,
    setProductModalOpen,
    isLcdCapsuleHit,
    setLcdCapsuleHit,
    isMovingToTarget,
    setIsMovingToTarget,
    setFormOverlay,
    isFormOverlay,
    setTotalDistance,
    setZeroRequiredDistance,
  } = useStore();
  const {
    handleFormModal,
    formModal,
    handleShowCart,
    handleShowMenu,
    handleShowProductList,
    handleItems,
    getData,
    showCart,
    showMenu,
    showItems,
    setShowActiveImage,
  } = useUIStore();

  const {
    handleShowGameOnEnterPopup,
    handleShowGame25WinPopup,
    isGameClick,
    setGameClick,
    startTimer,
    handleStartTimer,
    showGameUIPopup,
    handleFadeInAnimation,
    fadeIn,
    setFadeDelay,
  } = useGameStore();
  const playerRef = useRef();
  const [moveSpeed, setMoveSpeed] = useState(100);
  const [telportationPrestopDist, setTelportationPrestopDist] = useState(2);
  const { forward, backward, left, right } = usePersonControls();
  const easing = TWEEN.Easing.Cubic.Out;
  const entryAnimationEasing = TWEEN.Easing.Linear.None;
  const teleportEasing = TWEEN.Easing.Cubic.Out;
  const openProductModal = () => {
    handleShowProductList(false);
    handleItems(true);
    getData("helo");
  };

  useEffect(() => {
    if (!playerRef.current) return;
    // -14, 8, -50
    playerRef.current.setTranslation(
      {
        x: -10.857938766479492,
        y: 3,
        z: -43,
      },
      true
    );
  }, []);

  useEffect(() => {
    if (showItems && formModal) {
      handleShowMenu(false);
      handleShowCart(false);
      handleShowProductList(false);
      handleItems(false);
      setFormOverlay(false);
    }

    if (formModal) {
      handleShowMenu(false);
      handleShowCart(false);
      handleShowProductList(false);
      handleItems(false);
      setFormOverlay(false);
    }
  }, [formModal, showItems]);
  useEffect(() => {
    if (!playerRef.current) return;
  }, []);

  useFrame((state, delta) => {
    if (!playerRef.current) return;
    const velocity = playerRef.current.linvel();
    if (isEntryAnimationDone === false && !isDoubleClick && !isProductClick) {
      if (!isMovingToTarget) {
        // console.log("hitPoint", hitPoint);
        // Set-up Player
        const { x, y, z } = playerRef.current.translation();
        const playerInitialPosition = new THREE.Vector3(x, y, z);
        const playerFinalPosition = new THREE.Vector3(-10.5, 3, -17);
        const playerTotalDistance =
          playerInitialPosition.distanceTo(playerFinalPosition);
        const playerMoveDirection = direction
          .subVectors(playerFinalPosition, playerInitialPosition)
          .normalize();
        if (isProductClick) {
          playerRequiredDistance = playerTotalDistance;
        } else {
          playerRequiredDistance = playerTotalDistance;
        }
        const PlayerY = playerInitialPosition.y;
        const playerTargetPosition = playerInitialPosition
          .clone()
          .add(playerMoveDirection.multiplyScalar(playerRequiredDistance));
        playerTargetPosition.y = PlayerY;

        //Set-up camera
        const cameraInitialPosition = state.camera.position;
        const cameraFinalPosition = new THREE.Vector3(-10.5, 3, -17);
        const cameraTotalDistance =
          cameraInitialPosition.distanceTo(cameraFinalPosition);

        const cameraMoveDirection = direction
          .subVectors(cameraFinalPosition, cameraInitialPosition)
          .normalize();
        if (isProductClick) {
          cameraRequiredDistance = cameraTotalDistance;
        } else {
          cameraRequiredDistance = cameraTotalDistance;
        }
        const cameraTargetPosition = cameraInitialPosition
          .clone()
          .add(cameraMoveDirection.multiplyScalar(cameraRequiredDistance));
        setIsMovingToTarget(true);
        new TWEEN.Tween({ t: 0 })
          .to({ t: 1 })
          .duration(entryAnimationDuration)
          .easing(easing)
          .onUpdate((object) => {
            // Interpolate position based on the tween progress
            // move Player
            const playerAnimationPosition = new THREE.Vector3().lerpVectors(
              playerInitialPosition,
              playerTargetPosition,
              object.t
            );
            playerRef.current.setTranslation(
              {
                x: playerAnimationPosition.x,
                y: playerAnimationPosition.y,
                z: playerAnimationPosition.z,
              },
              true
            );
            if (object.t > 0.95) setPanEnabled(true);
            //move Camera
            const cameraAnimationPosition = new THREE.Vector3().lerpVectors(
              cameraInitialPosition,
              cameraTargetPosition,
              object.t
            );
            if (cameraAnimationPosition.z <= 50) {
              // console.log("HEIGHT VIEW");
              setRotateCamera(true);
            }
            // console.log(cameraAnimationPosition.z);
            state.camera.position.set(
              cameraAnimationPosition.x,
              cameraAnimationPosition.y,
              cameraAnimationPosition.z
            );
          })
          .onComplete(function (object) {
            setDoneEntryAnimation(true);
            setDoubleClick(false);
            setProductClick(false);
            setIsMovingToTarget(false);
            setTimeout(() => {
              setShowForm(true);
            }, 5000);
          })
          .start();
      }
    }

    if (!formModal) {
      if (!isDoubleClick && !isProductClick && !isGameClick) {
        if (!isMovingToTarget) {
          if (!isMovingWithJoystick) {
            frontVector.set(0, 0, 0);
            sideVector.set(0, 0, 0);
            playerRef.current.wakeUp();
            playerRef.current.setLinvel({
              x: 0,
              //not changing the height of the element
              y: velocity.y,
              z: 0,
            });
          }

          if (isMovingWithJoystick && isEntryAnimationDone) {
            if (joystickFoward != 0 || joystickTurn != 0) {
              // Math.abs(joystickTurn) >= 0.5 || Math.abs(joystickFoward) >= 0.5
              //   ? setMoveSpeed(150)
              //   : setMoveSpeed(100);
              direction
                .set(0, 0, -joystickFoward * joystickSpeed * delta)
                .applyEuler(state.camera.rotation);
              playerRef.current.wakeUp();
              playerRef.current.setLinvel({
                x: direction.x,
                //not changing the height of the element
                y: velocity.y,
                z: direction.z,
              });
            }
          } else {
            if (
              // !isTouchDevice &&
              isBrowser &&
              isEntryAnimationDone &&
              !showCart &&
              !showMenu &&
              !showItems &&
              !showGameUIPopup
            ) {
              //button clicked updating the amount of length
              frontVector.set(0, 0, backward - forward);
              sideVector.set(left - right, 0, 0);
              //set direction of movement
              direction
                .subVectors(frontVector, sideVector)
                .normalize()
                .multiplyScalar(MOVE_SPEED * delta)
                .applyEuler(state.camera.rotation);

              playerRef.current.wakeUp();
              playerRef.current.setLinvel({
                x: direction.x,
                //not changing the height of the element
                y: velocity.y,
                z: direction.z,
              });
            }
          }
        }
      } else {
        if (isDoubleClick || isProductClick || isGameClick) {
          if (!isMovingToTarget) {
            setIsMovingToTarget(true);
            const { x, y, z } = playerRef.current.translation();
            const playerPosition = new THREE.Vector3(x, y, z);
            const totalDistance = playerPosition.distanceTo(hitPoint);
            const moveDirection = direction
              .subVectors(hitPoint, playerPosition)
              .normalize();
            setTotalDistance(totalDistance);
            if (isProductClick && totalDistance - 7 > 0) {
              requiredDistance = totalDistance - 7;
            } else if (isLcdCapsuleHit && totalDistance - 7 > 0) {
              requiredDistance = totalDistance - 7;
            } else if (isDoubleClick && totalDistance - 7 > 0) {
              requiredDistance = totalDistance - 7;
            } else if (isGameClick && totalDistance - 7 > 0) {
              requiredDistance = totalDistance - 7;
            } else {
              requiredDistance = 0;
            }
            //SHOW PRODUCT POPUP IF THE DISTANCE IS CLOSE ENOUGH
            if (totalDistance <= 6 && isProductClick) {
              openProductModal();
            }
            const PlayerY = playerPosition.y;
            const targetPosition = playerPosition
              .clone()
              .add(moveDirection.multiplyScalar(requiredDistance));
            targetPosition.y = PlayerY;
            setPanEnabled(false);
            new TWEEN.Tween({ t: 0 })
              .to({ t: 1 })
              .duration(teleportingAnimationDuration * requiredDistance)
              .easing(teleportEasing)
              .onUpdate((object) => {
                const newPosition = new THREE.Vector3().lerpVectors(
                  playerPosition,
                  targetPosition,
                  object.t
                );

                if (object.t >= 0.9) {
                  // console.log("_____________________________");
                  setPanEnabled(true);

                  if (isProductClick && !showItems) {
                    handleItems(true);
                    handleShowProductList(false);
                    getData("helo");
                    setTimeout(() => setShowActiveImage(true), 1000);
                  }
                }
                playerRef.current.setTranslation(
                  {
                    x: newPosition.x,
                    y: newPosition.y,
                    z: newPosition.z,
                  },
                  true
                );
              })
              .onComplete(function (object) {
                if (isGameClick) handleStartTimer(true);
                setZeroRequiredDistance(false);
                handleFadeInAnimation(false);
                // setFadeDelay(0);
                setLcdCapsuleHit(false);
                setDoubleClick(false); // Reset the double-click flag
                setProductClick(false); // Reset the double-click flag
                setGameClick(false); // Reset the game-click flag
                setIsMovingToTarget(false);
              })
              .start();
          }
        }
      }
    }

    // moving camera
    const { x, y, z } = playerRef.current.translation();
    if (isEntryAnimationDone) {
      state.camera.position.set(x, 3, z);
    }

    TWEEN.update();
  });

  return (
    <>
      <RigidBody
        mass={1}
        ref={playerRef}
        friction={1}
        enabledRotations={[false, false, false]}
      >
        <mesh>
          <capsuleGeometry args={[0.1, 0.1]} />
          <CapsuleCollider args={[0.75, 0.75]} />
        </mesh>
      </RigidBody>
    </>
  );
};
