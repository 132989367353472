import React, { useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import useModelStore from "../store"; // Import the zustand store

function Moleculers({ url, scale, name }) {
  const group = useRef();
  const { scene } = useGLTF(url);
  const [isHovered, setIsHovered] = useState(false);
  const [productDetailCard, setProductDetailCard] = useState(false);

  // Get the model's dynamic position from the zustand store
  const position = useModelStore((state) => state.models[name] || [1000, 1000, 1000]);

  useFrame(() => {
    if (group.current) {
      // Update the model's position from the store
      group.current.position.set(position[0], position[1], position[2]);
      group.current.rotation.y += 0.01; // Optional rotation logic
      if (!isHovered) {
        group.current.rotation.y += 0.01; // Rotate when not hovered
      }
    }
  });

  const handlePointerOver = () => {
    document.body.style.cursor = "pointer";
    setIsHovered(true);
  };

  const handlePointerOut = () => {
    document.body.style.cursor = "default";
    setIsHovered(false);
  };

  return (
    <>
    <group ref={group} dispose={null} scale={scale} onPointerOver={handlePointerOver} onPointerOut={handlePointerOut} onClick={() => setProductDetailCard(true)}>
      <primitive object={scene} />
    </group>
    {productDetailCard && <p onClick={() => setProductDetailCard}>Card</p>}
    </>
  );
}

export default Moleculers;
