import React, { useState, useLayoutEffect, useEffect } from "react";
import "./index.css";
import { useTransition, a } from "react-spring";
import ProgressBar from "@ramonak/react-progress-bar";
import { useProgress } from "@react-three/drei";
import useStore from ".././../../utils2/3dstore";
export default function Loader() {
  const { setLoadingDone } = useStore();
  const [width, setWidth] = useState(0);
  const [finished, set] = useState(false);
  const [loadingClassName, setLoad] = useState("loading landing-contain");
  const { progress, loaded, total } = useProgress();

  useLayoutEffect(() => {
    setWidth((loaded / total) * 100);
    // console.log(typeof width)
    if (progress === 100 && loaded === total && !finished) {
      setTimeout(() => {
        set(true);
        setLoad("done-loading");
        setLoadingDone(true);
      }, 3000);

      // setTimeout(() => {
      //   set(true);
      //   setLoad("landing-contain loading done-loading");
      //   setLoadingDone(true);
      // }, 4000);
    }
  }, [width, progress, loaded, total, finished]);

  const props = useTransition(finished, null, {
    from: { opacity: 1, width: 0 },
    leave: { opacity: 0 },
    update: { width },
  });

  // return props.map(
  //   ({ item: finished, key, props: { opacity, width } }) =>
  //     !finished && (
  //       <a.div className={loadingClassName} key={key} style={{ opacity }}>
  //         <div className={loadingClassName}>
  //           <div className="main-bar-container">
  //             <img src="/assets/images/logo.png" className="page-img" />
  //             <div className="bar-container">
  //               <ProgressBar
  //                 completed={(loaded / total) * 100}
  //                 bgColor="#fff"
  //                 baseBgColor="#000"
  //                 borderRadius="0px"
  //                 height="11px"
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </a.div>
  //     )
  // );
  return (
    <div className={loadingClassName}>
      <div className="main-bar-container">
        <img src="/assets/images/logo.png" className="page-img" />
        <div className="bar-container">
          <ProgressBar
            completed={(loaded / total) * 100}
            bgColor="#fff"
            baseBgColor="#000"
            borderRadius="0px"
            height="11px"
          />
        </div>
      </div>
    </div>
  );
}
