import "./Home2.css";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Loading from "./components2/UI/Loading/Loader";
import Landscape from "./components2/UI/Landscape/Landscape";
import {
  EnterFullScreenButton,
  ExitFullScreenButton,
} from "./components2/Controls/FullScreen";
import UI from "./components2/UI/UI";
import Campaign from "./components2/Experience/Campaign";
import { useEffect, useState } from "react";
import CustomizeForm from "./CustomizeForm";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function GabarCustomEnv() {
    const handle = useFullScreenHandle();
    const [audioFile, setAudioFile] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const token = useSelector((state) => state.token);
    const navigate = useNavigate();

    useEffect(() => {
      const getEnvDetails = async () => {
        setLoading(false);
        const file = await fetchData(
          ApiConfig.fileMapping,
                setLoading,
                "GET",
                {},
                { authorization: `Bearer ${token}` }
        );
        if (file?.status === 401 || file?.status === 500) {  
          navigate("/login");      
        }        
        else if(file?.status === 200 ){
          setAudioFile(file?.data?.data?.audio_result[file?.data.data.audio_result.length - 1]?.audio_url || null);
          setVideoFile(file?.data?.data?.video_result[0]?.video_url || null)
        }
        setLoading(true);
      };
      getEnvDetails();
    }, []);

    const handleFiles = (audio, video) => {
        setAudioFile(audio);
        setVideoFile(video);
    };



  return (
    <>
    <div className="App">
      <div className="ReactFullScreen">
        <EnterFullScreenButton handle={handle} />
        <FullScreen handle={handle}>
          <div>
            <ExitFullScreenButton handle={handle} />
            <UI musicUrl={audioFile}/>
            <Campaign videoUrl={videoFile}/>
            {loading && <CustomizeForm onFilesSubmit={handleFiles} musicFile={audioFile} videoFile2={videoFile}/>}
          </div>
        </FullScreen>
      </div>
    </div>
    <Loading />
    <Landscape />
  </>
  );
}
