import React, { useEffect, useRef, useMemo, useState } from "react";
import {
  Text,
  useTexture,
  useGLTF,
  useAnimations,
  Billboard,
} from "@react-three/drei";
import { useGraph, useFrame, useThree } from "@react-three/fiber";
import { Skeletonutils2 } from "three-stdlib";
import { Color, DoubleSide, FrontSide, SRGBColorSpace } from "three";
import useGameStore from "../utils/gameStore";
import { formatTime } from "../utils/formatTime";
import { motion } from "framer-motion-3d";
import use3DStore from "../../../utils2/3dstore";
import { useTimer } from "react-timer-hook";
import useSoundStore from "../../UI/Audio/soundStore";
import useAnalytics from "../../../utils2/google-analytics/useGTAGTracker";
const TIME_LIMIT = 45;

export const fonts = {
  myriadPro_Italics: "./assets/fonts/MYRIADPRO-SEMIBOLDIT.OTF",
  myriadPro_Regular: "./assets/fonts/MYRIADPRO-REGULAR.OTF",
};
export const GameBoard = ({
  // key,
  id,
  question,
  options,
  position,
  rotation,
  active,
  onActivateClick,
  answer,
  expiryTimestamp,
  switched,
}) => {
  const { nodes, materials } = useGLTF("/assets/models/hologram.glb");

  const platform_light = useTexture("/assets/textures/platform_light.png");
  platform_light.flipY = false;
  platform_light.colorSpace = SRGBColorSpace;
  const platformEmissive = new Color("#fff");

  const game_board_emissive = useTexture(
    "/assets/textures/game-board_emissive.png"
  );
  game_board_emissive.flipY = false;
  game_board_emissive.colorSpace = SRGBColorSpace;
  const gameBoardEmissiveColor = new Color("#BCBCBC");

  const white = useTexture("/assets/textures/white.webp");
  white.flipY = false;
  white.colorSpace = SRGBColorSpace;

  const screenMaterialProps = {
    emissive: gameBoardEmissiveColor,
    roughness: 0.1,
    metalness: 0.3,
    side: DoubleSide,
    map: game_board_emissive,
    emissiveMap: game_board_emissive,
    emissiveIntensity: 1.2,
    toneMapped: false,
    // transparent: true,
    // depthTest:true,
    depthWrite: false,
    opacity: 0.5,
  };

  const emissionRayProps = {
    emissive: platformEmissive,
    roughness: 0.5,
    metalness: 0.1,
    side: DoubleSide,
    map: platform_light,
    emissiveMap: platform_light,
    emissiveIntensity: 7,
    toneMapped: false,
    transparent: true,
    // depthTest: true,
    depthWrite: false,
  };
  const platformEmissionProps = {
    // emissive: platformEmissive,
    roughness: 0.5,
    side: DoubleSide,
    // depthTest: true,
    depthWrite: false,
    transparent: true,
  };

  const animateOpacityProps = {
    // color: "#000",
    depthTest: true,
    depthWrite: true,
    transparent: true,
  };

  const { seconds, isRunning, pause, restart, start, minutes } = useTimer({
    expiryTimestamp,
    // onExpire: () => console.warn("onExpire called"),
    autoStart: false,
  });

  const [isQuestionAttempted, setIsQuestionAttempted] = useState(false);
  const [hovered, hover] = useState(null);
  const [selected, select] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [answered, setAnswered] = useState(null);
  const [isBoardClicked, setBoardClicked] = useState(false);
  const [isFlashing, setFlashing] = useState(null);
  const [flashValue, setFlashValue] = useState(1);
  const [isRunTime, setRunTimer] = useState(false);
  const [isLastFive, setLastFive] = useState(false);
  const [nextQuestionPopup, setNextQuestionPopup] = useState(false);

  const {
    handleCorrectAnswers,
    handleWrongAnswers,
    handleShowGameBoardAttemptedPopup,
    handleGamesPlayed,
    nextPlayableTime,
    gamesPlayed,
    correctAnswers,
    wrongAnswers,
    setGameClick,
    isUserGameRegistered,
    registeredEmail,
    handleShowGameOnEnterPopup,
    startTimer,
    handleStartTimer,
    isGameLocked,
    setUserGameRegistered,
    handleLastGameTime,
    activeBoardIndex,
    handleActiveBoardIndex,
    handleRegisteredEmail,
    isGameClick,
    flashIndex,
    setFlashIndex,
    setGamePlaying,
    isGamePlaying,
    setGameStartTimestamp,
    handleShowGameNextQuestionPopup,
    showGameNextQuestionPopup,
  } = useGameStore();

  const { trackQuestionBoardInitialClick, trackQuestionBoardAnswer } =
    useAnalytics();

  const { setCountDown, setCorrectAnswer, setWrongAnswer, setQuizEnd } =
    useSoundStore();

  const { setHitPoint, isMovingToTarget, isEntryAnimationDone } = use3DStore();

  const answerQuestion = (e, isCorrect, selectedIndex) => {
    console.log("ANSWERING ", id);
    e && e.stopPropagation();
    pause();
    setCountDown(false);

    if (!isQuestionAttempted && gamesPlayed < 3) {
      const secondQuestion = gamesPlayed === 1;
      const thirdQuestion = gamesPlayed === 2;
      const gameEnd = secondQuestion && correctAnswers === 0 && !isCorrect;
      select(selectedIndex);
      handleGamesPlayed(gamesPlayed + 1);
      setIsQuestionAttempted(true);
      setRunTimer(false);

      if (isCorrect) {
        setCorrectAnswer(true);
        handleCorrectAnswers(correctAnswers + 1);
        setIsCorrect(true);
        trackQuestionBoardAnswer(`Question_Board_${id + 1}_Correct_Answer`);
      } else {
        setWrongAnswer(true);
        handleWrongAnswers(wrongAnswers + 1);
        setIsCorrect(false);
        trackQuestionBoardAnswer(`Question_Board_${id + 1}_Wrong_Answer`);
      }

      setTimeout(() => {
        if (flashIndex === id) {
          setFlashIndex((flashIndex + 1) % 3);
        } else {
          setFlashIndex((id + 1) % 3);
        }
        setAnswered(true);
        setLastFive(false);
        if (!thirdQuestion && !gameEnd) {
          setNextQuestionPopup(true);
          handleShowGameNextQuestionPopup(true);
          setTimeout(() => {
            setNextQuestionPopup(false);
            handleShowGameNextQuestionPopup(false);
          }, 6000);
        }
        setTimeout(() => {
          setGamePlaying(false);
          setCorrectAnswer(false);
          setWrongAnswer(false);
          setFlashing(false);
        }, 2000);
      }, 1000);
    }
  };

  const activateBoard = (e) => {
    e.stopPropagation();
    setHitPoint(e.point);
    if (flashIndex !== id && gamesPlayed === 0) {
      setFlashIndex(id);
    }
    if (!isBoardClicked)
      trackQuestionBoardInitialClick(`Question_Board_${id + 1}_Initial_Click`);
    setBoardClicked(true);
    if (!isUserGameRegistered && !isGameLocked && !isGamePlaying) {
      console.log("showingOnEnterPopup");
      handleShowGameOnEnterPopup(true);
    } else if (isGameLocked && !isGamePlaying) {
      onActivateClick();
    } else if (
      isQuestionAttempted &&
      gamesPlayed < 3 &&
      (new Date() >= nextPlayableTime || nextPlayableTime === null) &&
      !isGamePlaying
    ) {
      handleShowGameBoardAttemptedPopup(true);
    } else {
      console.log("MOVING To:", id);
      if (!isMovingToTarget) {
        setGameClick(true);
      }
    }
  };

  useEffect(() => {
    console.log("GAME PLAYING: ", isGamePlaying);
    if (!isGameLocked && nextPlayableTime !== null && !isGamePlaying) {
      // console.log("GAME UNLOCKED");
      setIsQuestionAttempted(false);
      hover(null);
      select(null);
      setIsCorrect(null);
      setAnswered(null);
      handleCorrectAnswers(0);
      handleWrongAnswers(0);
      handleGamesPlayed(0);
      handleLastGameTime(new Date());
      // console.log("registeredEmail===================> ", registeredEmail);
      handleRegisteredEmail(null);
      setUserGameRegistered(false);
      // handleActiveBoardIndex(null);
      setBoardClicked(false); //may not be necessary
      setFlashing(null);
      setFlashIndex(activeBoardIndex ? activeBoardIndex : 0);
      setFlashValue(1);
      setRunTimer(false);
      setLastFive(false);
      setGameStartTimestamp(null);
      setGamePlaying(false);
      setCorrectAnswer(false);
      setWrongAnswer(false);
      setNextQuestionPopup(false);
      setQuizEnd(false);
    }
  }, [isGameLocked]);

  useEffect(() => {
    if (id === flashIndex && !isQuestionAttempted) {
      const time = new Date();
      time.setHours(time.getHours() + 1);
      time.setSeconds(time.getSeconds() + 45);
      restart(time);
      setFlashing(true);
      // console.log({ "Flashing Index": id, isRunning: isRunning });
    }
  }, [flashIndex, isFlashing, isGameLocked, isGamePlaying]);

  useEffect(() => {
    if (isGamePlaying && isFlashing && !isQuestionAttempted && !active) {
      // console.log("PAUSED FLASHING");
      if (!isUserGameRegistered) {
        pause();
        setFlashing(null);
      }
    }
  }, [isGamePlaying]);

  useEffect(() => {
    if (
      isGameClick &&
      isUserGameRegistered &&
      isBoardClicked &&
      !isQuestionAttempted
    ) {
      // console.log("ACTIVATE:", id);
      onActivateClick();
      // setBoardClicked(false);
    }
  }, [isUserGameRegistered, isGameClick]);

  useEffect(() => {
    // console.log("SWITCHED DONE :", id)
    if (switched) {
      answerQuestion(null, false, -1);
    }
  }, [switched]);

  useEffect(() => {
    if (gamesPlayed === 3) return;
    if (isFlashing && isEntryAnimationDone) {
      setFlashValue((prev) => -prev);
    }

    if (seconds === 5 && isRunTime) {
      setLastFive(true);
    }

    if (seconds === 10 && isRunTime) {
      setCountDown(true);
    }

    console.log("SECONDS: ", seconds, " ID: ", id);
    if (seconds - 1 === 0 && isRunTime) {
      answerQuestion(null, false, -1);
    }
  }, [seconds]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (!active) {
      pause();
    }

    if (active && !answered) {
      if (startTimer && !isRunTime && !isGamePlaying ) {
        console.log("TIMER STARTED");
        if (gamesPlayed === 0) {
          setGameStartTimestamp(new Date());
        }
        // setCountDown(true);
        // const time = new Date();
        // time.setSeconds(45);
        // console.log(time.getSeconds())
        // restart(time);
        const time = new Date();
        // time.setHours(0);
        // time.setMinutes(0);
        time.setSeconds(time.getSeconds() + 45);
        restart(time);
        setRunTimer(true);
        handleStartTimer(false);
        setGamePlaying(true);
      }
    }
  }, [active, answered, startTimer, isRunTime]);

  return (
    <group dispose={null} position={position}>
      <Billboard follow>
        <mesh
          geometry={nodes.Screen_Plane.geometry}
          position={[0, 3.411, 0.003]}
          rotation={[0, Math.PI, 0]}
        >
          <motion.meshStandardMaterial
            {...screenMaterialProps}
            animate={{
              opacity: nextQuestionPopup
                ? 0.5
                : isGameLocked || answered
                ? 0
                : active && !answered && isUserGameRegistered
                ? 0.5
                : 0,
            }}
            transition={{ ease: "easeOut", duration: 2 }}
          />
          <Text
            position={[0, 0, 0.0001]}
            fontSize={0.2}
            maxWidth={2.9}
            scale={1}
            font={fonts.myriadPro_Italics}
            textAlign="center"
            anchorX="center"
            anchorY="middle"
            lineHeight={1}
          >
            Please head over to the next question.
            <motion.meshStandardMaterial
              {...animateOpacityProps}
              color={"#000"}
              animate={{
                opacity: nextQuestionPopup ? 1 : 0,
              }}
              transition={{ ease: "easeOut", duration: 2 }}
            />
          </Text>
          <mesh
            name="plane_board"
            geometry={nodes.Text_Plane.geometry}
            material={materials.Text_Plane_Material}
            position={[0, 1.039, 0.003]}
            onClick={(e) => e.stopPropagation()}
            onDoubleClick={(e) => e.stopPropagation()}
            renderOrder={1}
          >
            <group renderOrder={1}>
              <Text
                position={[0, 0, 0.0001]}
                fontSize={0.16}
                maxWidth={2.9}
                scale={1}
                font={fonts.myriadPro_Italics}
                textAlign="center"
                anchorX="center"
                anchorY="middle"
                lineHeight={1}
              >
                {question}
                <motion.meshStandardMaterial
                  {...animateOpacityProps}
                  color={"#000"}
                  animate={{
                    opacity:
                      isGameLocked || answered
                        ? 0
                        : active && !answered && isUserGameRegistered
                        ? 1
                        : 0,
                  }}
                  transition={{ ease: "easeOut", duration: 2 }}
                />
              </Text>
            </group>
          </mesh>
          <mesh
            name="plane_board"
            geometry={nodes.Options_Plane.geometry}
            material={materials.Options_Plane_Highlighted}
            position={[0, 0.114, 0.03]}
            onPointerOver={() => !isQuestionAttempted && hover(1)}
            onPointerOut={() => hover(null)}
            onClick={(e) => {
              e.stopPropagation();
              !isMovingToTarget &&
                active &&
                answerQuestion(e, options.option_1 === answer, 1);
            }}
            onDoubleClick={(e) => {
              e.stopPropagation();
              !isMovingToTarget &&
                active &&
                answerQuestion(e, options.option_1 === answer, 1);
            }}
            renderOrder={1}
          >
            <motion.meshStandardMaterial
              color={
                isQuestionAttempted && isCorrect && selected === 1 //GREEN
                  ? "#00ff00"
                  : isQuestionAttempted && !isCorrect && selected === 1 //RED
                  ? "#ff0000"
                  : !isQuestionAttempted && hovered === 1 //YELLOW ROSE
                  ? "#000"
                  : "#fff"
              }
              transparent
              animate={{
                opacity:
                  isGameLocked || answered
                    ? 0
                    : active && !answered && isUserGameRegistered
                    ? 1
                    : 0,
              }}
              transition={{ ease: "easeOut", duration: 2 }}
            />
            <group renderOrder={1}>
              <Text
                position={[0, 0, 0.0001]}
                fontSize={0.17}
                maxWidth={2.9}
                font={fonts.myriadPro_Regular}
                scale={1}
                textAlign="center"
                anchorX="center"
                anchorY="middle"
                lineHeight={1}
              >
                {options.option_1}

                <motion.meshStandardMaterial
                  {...animateOpacityProps}
                  color={
                    !isQuestionAttempted && hovered === 1 ? "#fff" : "#000"
                  }
                  animate={{
                    opacity:
                      isGameLocked || answered
                        ? 0
                        : active && !answered && isUserGameRegistered
                        ? 1
                        : 0,
                  }}
                  transition={{ ease: "easeOut", duration: 2 }}
                />
              </Text>
            </group>
          </mesh>
          <mesh
            name="plane_board"
            geometry={nodes.Options_Plane001.geometry}
            material={materials.Option_Plane}
            position={[0, -0.413, 0.03]}
            onPointerOver={() => !isQuestionAttempted && hover(2)}
            onPointerOut={() => hover(null)}
            onClick={(e) => {
              e.stopPropagation();
              !isMovingToTarget &&
                active &&
                answerQuestion(e, options.option_2 === answer, 2);
            }}
            onDoubleClick={(e) => {
              e.stopPropagation();
              !isMovingToTarget &&
                active &&
                answerQuestion(e, options.option_2 === answer, 2);
            }}
            renderOrder={1}
          >
            <motion.meshStandardMaterial
              color={
                isQuestionAttempted && isCorrect && selected === 2 //GREEN
                  ? "#00ff00"
                  : isQuestionAttempted && !isCorrect && selected === 2 //RED
                  ? "#ff0000"
                  : !isQuestionAttempted && hovered === 2 //YELLOW ROSE
                  ? "#000"
                  : "#fff"
              }
              {...animateOpacityProps}
              animate={{
                opacity:
                  isGameLocked || answered
                    ? 0
                    : active && !answered && isUserGameRegistered
                    ? 1
                    : 0,
              }}
              transition={{ ease: "easeOut", duration: 2 }}
            />
            <group renderOrder={1}>
              <Text
                position={[0, 0, 0.0001]}
                fontSize={0.17}
                maxWidth={2.9}
                font={fonts.myriadPro_Regular}
                scale={1}
                textAlign="center"
                anchorX="center"
                anchorY="middle"
                lineHeight={1}
              >
                {options.option_2}

                <motion.meshStandardMaterial
                  {...animateOpacityProps}
                  color={
                    !isQuestionAttempted && hovered === 2 ? "#fff" : "#000"
                  }
                  animate={{
                    opacity:
                      isGameLocked || answered
                        ? 0
                        : active && !answered && isUserGameRegistered
                        ? 1
                        : 0,
                  }}
                  transition={{ ease: "easeOut", duration: 2 }}
                />
              </Text>
            </group>
          </mesh>
          {options.option_3 && (
            <mesh
              name="plane_board"
              geometry={nodes.Options_Plane002.geometry}
              material={materials.Option_Plane}
              position={[0, -0.94, 0.03]}
              onPointerOver={() => !isQuestionAttempted && hover(3)}
              onPointerOut={() => hover(null)}
              onClick={(e) => {
                e.stopPropagation();
                !isMovingToTarget &&
                  active &&
                  answerQuestion(e, options.option_3 === answer, 3);
              }}
              onDoubleClick={(e) => {
                e.stopPropagation();
                !isMovingToTarget &&
                  active &&
                  answerQuestion(e, options.option_3 === answer, 3);
              }}
              renderOrder={1}
            >
              <motion.meshStandardMaterial
                color={
                  isQuestionAttempted && isCorrect && selected === 3 //GREEN
                    ? "#00ff00"
                    : isQuestionAttempted && !isCorrect && selected === 3 //RED
                    ? "#ff0000"
                    : !isQuestionAttempted && hovered === 3 //YELLOW ROSE
                    ? "#000"
                    : "#fff"
                }
                {...animateOpacityProps}
                animate={{
                  opacity:
                    isGameLocked || answered
                      ? 0
                      : active && !answered && isUserGameRegistered
                      ? 1
                      : 0,
                }}
                transition={{ ease: "easeOut", duration: 2 }}
              />
              <group renderOrder={1}>
                <Text
                  position={[0, 0, 0.0001]}
                  fontSize={0.17}
                  maxWidth={2.9}
                  font={fonts.myriadPro_Regular}
                  scale={1}
                  textAlign="center"
                  anchorX="center"
                  anchorY="middle"
                  lineHeight={1}
                >
                  {options.option_3}

                  <motion.meshStandardMaterial
                    {...animateOpacityProps}
                    color={
                      !isQuestionAttempted && hovered === 3 ? "#fff" : "#000"
                    }
                    animate={{
                      opacity:
                        isGameLocked || answered
                          ? 0
                          : active && !answered && isUserGameRegistered
                          ? 1
                          : 0,
                    }}
                    transition={{ ease: "easeOut", duration: 2 }}
                  />
                </Text>
              </group>
            </mesh>
          )}
          <group name="Loader" position={[0, -1.55, 0.018]} renderOrder={1}>
            <group renderOrder={1}>
              <Text
                position={[0, 0.25, 0.0001]}
                fontSize={0.17}
                maxWidth={2.5}
                font={fonts.myriadPro_Regular}
                scale={1}
                textAlign="justify"
                anchorX="center"
                anchorY="center"
                lineHeight={1}
              >
                {formatTime(
                  seconds === 0 && isQuestionAttempted
                    ? 45
                    : !isRunTime && isQuestionAttempted
                    ? seconds
                    : !isRunTime
                    ? 45
                    : seconds + 1 === 1
                    ? 45
                    : seconds,
                  seconds - 1 === 0
                )}

                <motion.meshStandardMaterial
                  {...animateOpacityProps}
                  color={isLastFive ? "#ff0000" : "#000"}
                  animate={{
                    opacity:
                      isGameLocked || answered
                        ? 0
                        : active && !answered && isUserGameRegistered
                        ? 1
                        : 0,
                  }}
                  transition={{ ease: "easeOut", duration: 2 }}
                />
              </Text>
            </group>

            <mesh
              geometry={nodes["timer-background"].geometry}
              material={nodes["timer-background"].material}
              scale={[1.5, 0.35, 1]}
              onClick={(e) => e.stopPropagation()}
              onDoubleClick={(e) => e.stopPropagation()}
              // renderOrder={1}
            >
              <motion.meshStandardMaterial
                color={"#000"}
                {...animateOpacityProps}
                animate={{
                  opacity:
                    isGameLocked || answered
                      ? 0
                      : active && !answered && isUserGameRegistered
                      ? 1
                      : 0,
                }}
                transition={{ ease: "easeOut", duration: 2 }}
              />
            </mesh>
            <motion.mesh
              name="plane_board"
              geometry={nodes["timer-loader"].geometry}
              material={materials["timer-material"]}
              position={[-0.599, 0, 0.001]}
              scale={[0.004, 0.15, 1]}
              initial={{ scaleX: 0.598 }}
              animate={{
                scaleX:
                  seconds === 0 && isQuestionAttempted
                    ? 0.598
                    : !isRunTime && isQuestionAttempted
                    ? (seconds / TIME_LIMIT) * 0.598
                    : !isRunTime
                    ? 0.598
                    : ((seconds + 1 === 1 ? 45 : seconds) / TIME_LIMIT) * 0.598,
              }}
              // transition={{ ease: "easeOut", duration: 2 }}
              transition={{ ease: "easeOut", duration: 1 }}
              onClick={(e) => e.stopPropagation()}
              onDoubleClick={(e) => e.stopPropagation()}
              // renderOrder={1}
            >
              <motion.meshStandardMaterial
                color={isLastFive ? "#ff0000" : "#fff"}
                {...animateOpacityProps}
                animate={{
                  opacity:
                    isGameLocked || answered
                      ? 0
                      : active && !answered && isUserGameRegistered
                      ? 1
                      : 0,
                }}
                transition={{ ease: "easeOut", duration: 2 }}
              />
            </motion.mesh>
          </group>
        </mesh>
      </Billboard>
      <group
        name="Hologram"
        onClick={(e) => activateBoard(e)}
        onDoubleClick={(e) => activateBoard(e)}
      >
        <mesh
          geometry={nodes.Platform_1.geometry}
          material={materials.Emission_Ray_Big_Material}
        >
          <motion.meshStandardMaterial
            {...emissionRayProps}
            animate={{
              opacity:
                isGameLocked || answered
                  ? 0
                  : active && !answered
                  ? 1
                  : isFlashing
                  ? flashValue
                  : 0.4,
            }}
            transition={{ ease: "easeOut", duration: 1 }}
          />
        </mesh>
        <mesh
          geometry={nodes.Platform_2.geometry}
          material={materials.Platform_Material}
        />
        <mesh geometry={nodes.Platform_3.geometry}>
          <motion.meshStandardMaterial
            {...emissionRayProps}
            animate={{
              opacity:
                isGameLocked || answered
                  ? 0
                  : active && !answered
                  ? 1
                  : isFlashing
                  ? flashValue
                  : 0.4,
            }}
            transition={{ ease: "easeOut", duration: 2 }}
          />
        </mesh>
      </group>
    </group>
  );
};

useGLTF.preload("/assets/models/hologram.glb");
