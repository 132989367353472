import { useRef, useEffect, useState } from "react";
import "./sound.css";
import useSoundStore from "./soundStore";
import useGameStore from "../../Game/utils/gameStore";
import useAudioContainer from "../../../utils2/useAudioContainer";


const Player = ({ musicUrl, finalScreen }) => {
    const audioElement = useRef(null); // Initialize with null to avoid undefined errors
    const { isPlaying, isMuted, isCountDown } = useSoundStore();
    const { isGamePlaying } = useGameStore();
    const { isProductCartOpen, audioUrl, playAudio } = useAudioContainer();

    const currentAudioUrl = isProductCartOpen && audioUrl ? audioUrl : musicUrl;
  
    useEffect(() => {
      if(!finalScreen){
      if (audioElement.current) {
        if (isPlaying) {
          audioElement.current.play().catch(error => {
            console.error('Error playing audio:', error);
          });
        } else {
          audioElement.current.pause();
        }
      }
      }
    }, [isPlaying]); 
  
    useEffect(() => {
      if (audioElement.current) {
        if (isGamePlaying || isCountDown) {
          audioElement.current.volume = 0.1; // 10% volume
        } else {
          audioElement.current.volume = 0.5; // 50% volume
        }
      }
    }, [isGamePlaying, isCountDown]); 
  
    useEffect(() => {
      if (audioElement.current) {
        audioElement.current.muted = isMuted;
      }
    }, [isMuted]); // Add isMuted as a dependency
  
    useEffect(() => {
      if (audioElement.current) {
        // Pause, load new source, and play
        audioElement.current.pause();
        audioElement.current.load();
        audioElement.current.play().catch(error => {
          console.error('Error playing new audio:', error);
        });
      }
    }, [currentAudioUrl,playAudio]); // Run this effect when musicUrl changes
  
    return (
      <div className="sound-control">
        <audio loop ref={audioElement}>
          <source src={currentAudioUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    );
  };
  
  
  const SoundOnOff = () => {
    const { isMuted, setMuted } = useSoundStore();
    const [isMuteVisible, setMuteVisibity] = useState(0);
    const [isUnmuteVisible, setUnMuteVisibility] = useState(1);
  
    useEffect(() => {
      if (isMuted) {
        setUnMuteVisibility(0);
        setMuteVisibity(1);
      } else {
        setUnMuteVisibility(1);
        setMuteVisibity(0);
      }
    }, [isMuted]);
    return (
      <div
        className="music_icon"
        onClick={() => {
          setMuted(!isMuted);
        }}
      >
        <img
          className="sound-icon-img hide-selection"
          style={{
            fontSize: 30,
            opacity: isUnmuteVisible,
            position: "absolute",
            zIndex: 10,
          }}
          src="/assets/images/audible.png"
        />
        <img
          className="sound-icon-img hide-selection"
          style={{
            fontSize: 30,
            opacity: isMuteVisible,
            position: "absolute",
            zIndex: 11,
          }}
          src="/assets/images/muted.png"
        />
      </div>
    );
  };
  
  export { SoundOnOff, Player };