// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productDetail {
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 20vw;
  height: 100vh;
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/gabar/addToCart.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,MAAA;EACA,aAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;AACJ","sourcesContent":[".productDetail{\n    position: absolute;\n    top: 0;\n    z-index: 9999;\n    width: 20vw;\n    height: 100vh;\n    background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
