import React from 'react'
import './addToCart.scss'

const AddtoCart = ({ data, closePopup}) => {
    console.log(data);
    
  return (
    <div  className='productDetail'>
        <p>Add to cart</p>
        <div className='close' onClick={()=> closePopup(false)} style={{cursor: 'pointer'}}>Close</div>
        <div>
            <img src={data.product_image} alt='product image' style={{width: '150px', height: '150px', objectFit: 'cover'}} />
            <p>{data.name}</p>
            <p  dangerouslySetInnerHTML={{ __html:data.product_description}}/>
        </div>
    </div>
  )
}

export default AddtoCart