// import React, {useState, useRef } from "react";
// import { useGLTF } from "@react-three/drei";
// import { useFrame } from "@react-three/fiber";

// function Moleculers({ url, position, scale }) {
//   const group = useRef();
//   const { scene } = useGLTF(url);
//   const [isHovered, setIsHovered] = useState(false); 

//   useFrame(() => {
//     if (group.current && !isHovered) {
//       group.current.rotation.y += 0.01; 
//     }
//   });

//   const handlePointerOver = (event) => {
//     document.body.style.cursor = 'pointer'; 
//     setIsHovered(true);
//   };

//   // Handle pointer out event (hover end)
//   const handlePointerOut = (event) => {
//     document.body.style.cursor = 'default'; 
//     setIsHovered(false);// Revert cursor back to default
//   };
  

//   return (
//     <group 
//         ref={group} 
//         dispose={null} 
//         position={position}
//         scale={8}
//         onClick={() => alert('hii')}
//         onPointerOver={handlePointerOver} 
//         onPointerOut={handlePointerOut} 
//     >
//       <primitive object={scene} />
//     </group>
//   );
// }

// function Molecules1({dropLocation, productData,productLocationChange}) {
//   console.log(productLocationChange);
  
//   const models = [
//     {
//       url: "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/306/product/slingback_sandal_heels.glb",
//       // position: [-5.1, 2, -6.178],
//       position: [-0.6382995818120456, 0.24920681118965105, 6.740224713916444],
//       // position: [dropLocation.x, dropLocation.y, dropLocation.z],
//       scale: [1, 1, 1], // Normal scale
//     },
//     {
//       url: "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/306/product/gladiator_sandal_heels.glb",
//       // position: [-26.54,-.75 , -7.478],
//       // position: [dropLocation.x || 0, dropLocation.y || 0, dropLocation.z || 0],
//       scale: [1, 1, 1], // Normal scale
//     },
//   ];

//   return (
//     <>
//       {/* {models.map((model, index) => (
//         <Moleculers
//           key={index}
//           url={model.url}
//           position={model.position}
//           scale={model.scale} // Use the provided scale
//         />
//       ))} */}
//       {productData.map(product => (
//          <Moleculers
//          key={product.product_uid}
//          url={product.product_3D_model_url}
//          position={[-0.6382995818120456, 0.24920681118965105, 6.740224713916444]}
//          scale={[1,1,1]} 
//        />
//       ))}
//     </>
//   );
// }

// export default Molecules1;


// import React, { useState, useRef, useEffect } from "react";
// import { useGLTF } from "@react-three/drei";
// import { useFrame } from "@react-three/fiber";

// // Function to convert product location string into a 3D coordinate
// const getPositionFromLocation = (location) => {
//   const locations = {
//     location1: [-4.891337813128718, 2, -5.720227980977997], // Example positions
//     location2: [1.9916866740908432, 2, 13.23984951053118],
//     location3: [-5.953889676150517, 2, 21.4005676390457],
//     location4: [-30.49039677915537, 2, 13.560054539298182],
//     location5: [-26.521714223481858, 2, -7.335591221166235],
//   };
//   return locations[location] || [0, 0, 0]; 
// };

// function Moleculers({ url, scale, initialPosition, dynamicPosition }) {
//   const group = useRef();
//   const { scene } = useGLTF(url);
//   const [isHovered, setIsHovered] = useState(false);

//   // Update position dynamically in useFrame without causing re-render
//   useFrame(() => {
//     if (group.current) {
//       const { x, y, z } = dynamicPosition.current; // Access current position from ref
//       group.current.position.set(x, y, z);

//       if (!isHovered) {
//         group.current.rotation.y += 0.01; // Rotate when not hovered
//       }
//     }
//   });

//   const handlePointerOver = () => {
//     document.body.style.cursor = "pointer";
//     setIsHovered(true);
//   };

//   const handlePointerOut = () => {
//     document.body.style.cursor = "default";
//     setIsHovered(false);
//   };

//   return (
//     <group
//       ref={group}
//       dispose={null}
//       scale={scale}
//       onPointerOver={handlePointerOver}
//       onPointerOut={handlePointerOut}
//     >
//       <primitive object={scene} />
//     </group>
//   );
// }

// function Molecules1({ productData, productLocationChange }) {
//   const positionsRef = useRef({}); // Store positions of products in a ref

//   // Initialize positions for each product
//   useEffect(() => {
//     productData.forEach((product) => {
//       const uid = product.product_uid;
//       // If no position is set yet, initialize with the default position
//       if (!positionsRef.current[uid]) {
//         positionsRef.current[uid] = { x: 0, y: 0, z: 0 };
//       }
//     });
//   }, [productData]);

//   // Update positions based on productLocationChange
//   useEffect(() => {
//     if(productLocationChange.length){
//       console.log(productLocationChange);
      
//       productLocationChange.forEach((change) => {
//       const { product_uid, productPosition } = change;
//       if (productPosition && positionsRef.current[product_uid]) {
//         // Update the position of the specific product UID
//         const newPosition = getPositionFromLocation(productPosition);
//         positionsRef.current[product_uid] = {
//           x: newPosition[0],
//           y: newPosition[1],
//           z: newPosition[2],
//         };
//       }
//       });
//     }
//   }, [productLocationChange]);

//   return (
//     <>
//       {productData.map((product) => {
//         const dynamicPosition = positionsRef.current[product.product_uid] || {
//           x: 0,
//           y: 0,
//           z: 0,
//         };

//         return (
//           <Moleculers
//             key={product.product_uid}
//             url={product.product_3D_model_url}
//             scale={[5, 5, 5]}
//             initialPosition={[0, 0, 0]} // Initial position on load
//             dynamicPosition={{ current: dynamicPosition }} // Dynamic position from ref
//           />
//         );
//       })}
//     </>
//   );
// }

// export default Molecules1;




import React from "react";
import Moleculers from "./Moleculers";


function Molecules1({ productData}) {
  return (
    <>
      {productData.map(product => (
        <Moleculers
          key={product.product_uid}
          url={product.product_3D_model_url}
          name={product.product_uid}
          scale={[5,5,5]} 
        />
      ))}
    </>
  );
}

export default Molecules1;