import "./styles.css";
import useStore from ".././../../utils2/3dstore";

function Overlay() {
  const { isReady, setReady, isEntryAnimationDone, setDoneEntryAnimation } =
    useStore();
  const startJourney = () => {
    setReady(true);
    if (isEntryAnimationDone === undefined) setDoneEntryAnimation(false);
  };
  return (
    <>
      <div
        // onClick={() => console.log("OVERLAY CLICKED")}
        className={`overlay ${
          isReady ? "overlay_ready" : "overlay_notready notready"
        } ${isReady && "overlay_clicked"}`}
      >
        {/* <div className="stack">
          <button className="button_overlay" onClick={startJourney}>Start</button>
        </div> */}
      </div>
    </>
  );
}

export default Overlay;
