// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container-parent{
    position: absolute;
    z-index:4;
    /* left: 25%; */
}
.product-block{
    width: 200px;
    height: 200px;
    background-color: bisque;
    margin-top: 10px;
    cursor: pointer;
}
.main-container-products{
    
    overflow: scroll;
    height: 400px;
    width: 600px;
    background-color:#fff;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  

}
.listing-product{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}
.product-Images{
    height: 50px;
    width: 50px;
    border-radius: 10px;
    margin-top: 5px;
}
.product-title{
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    padding: 2px;
    overflow: hidden;
}
.product-descriptionss{
    font-size: 12px;
    font-weight: 500;
    height: 75px;
    width: 170px;
    overflow: scroll;
}
.product-price{
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components2/UI/ProductList/productlist.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,eAAe;AACnB;AACA;IACI,YAAY;IACZ,aAAa;IACb,wBAAwB;IACxB,gBAAgB;IAChB,eAAe;AACnB;AACA;;IAEI,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,qBAAqB;IACrB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;;;AAG1B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;;AAEvB;AACA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB","sourcesContent":[".main-container-parent{\n    position: absolute;\n    z-index:4;\n    /* left: 25%; */\n}\n.product-block{\n    width: 200px;\n    height: 200px;\n    background-color: bisque;\n    margin-top: 10px;\n    cursor: pointer;\n}\n.main-container-products{\n    \n    overflow: scroll;\n    height: 400px;\n    width: 600px;\n    background-color:#fff;\n    padding: 10px;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n  \n\n}\n.listing-product{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  \n}\n.product-Images{\n    height: 50px;\n    width: 50px;\n    border-radius: 10px;\n    margin-top: 5px;\n}\n.product-title{\n    font-size: 15px;\n    font-weight: 500;\n    width: 100%;\n    padding: 2px;\n    overflow: hidden;\n}\n.product-descriptionss{\n    font-size: 12px;\n    font-weight: 500;\n    height: 75px;\n    width: 170px;\n    overflow: scroll;\n}\n.product-price{\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
