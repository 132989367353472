// import React, { useState, useCallback } from "react";

// function Box({ onDropImage , productData, onApply}) {
//   const [isDragging, setIsDragging] = useState(false);
//   const [position, setPosition] = useState({ x: 0, y: 0 });


//   // const [formValues, setFormValues] = useState({
//   //   productList: '',
//   //   productPosition: '',
//   // });

//   const [formValues, setFormValues] = useState(
//     productData.map((product) => ({
//       product_uid: product.brand_product_masters[0].product_uid,
//       productPosition: "",
//     }))
//   );

//   // const handleChange = (e) => {
//   //   const { name, value } = e.target;
//   //   setFormValues((prevValues) => ({
//   //     ...prevValues,
//   //     [name]: value,
//   //   }));
//   // };


//   const handlePositionChange = (product_uid, position) => {
//     setFormValues((prevValues) =>
//       prevValues.map((item) =>
//         item.product_uid === product_uid
//           ? { ...item, productPosition: position }
//           : item
//       )
//     );
//   };

//   const handleSubmit = () => {
//     onApply(formValues);
//   };

//   const handleDragStart = (e) => {
//     setIsDragging(true);
//   };

//   const handleDrag = (e) => {
//     if (isDragging) {
//       setPosition({
//         x: e.clientX,
//         y: e.clientY,
//         name: 'img1'
//       });
//     }
//   };

//   const handleDrop = (e) => {
//     setIsDragging(false);
//     onDropImage(position); // Send the final drop position to the Three.js scene
//   };

//   console.log(productData);
  

//   return (
//     <>
//     {/* <div
//       draggable
//       onDragStart={handleDragStart}
//       onDrag={handleDrag}
//       onDragEnd={handleDrop}
//       style={{
//         position: "absolute",
//         bottom: "0",
//         left: "50%",
//         transform: "translateX(-50%)",
//       }}
//     >
//       <img
//         src="https://images.pexels.com/photos/27837704/pexels-photo-27837704/free-photo-of-barren-mountains-in-snow.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load"
//         style={{
//           width: "50px",
//           height: "50px",
//           cursor: "pointer",
//         }}
//       />
//     </div> */}



//     <div
//     style={{
//       position: "absolute",
//       bottom: "0",
//       left: "50%",
//       transform: "translateX(-50%)",
//       display : "flex",
//       gap: "10px",
//       padding: "13px",
//       borderRadius: "5px",
//       background: "#fff"
//     }}
//     >
//       {/* {productData.map(product => (
//             <div
//             draggable
//             // onDragStart={handleDragStart}
//             // onDrag={handleDrag}
//             // onDragEnd={handleDrop}
//           >
//             <img
//               src={product.brand_product_masters[0].product_image}
//               style={{
//                 width: "50px",
//                 height: "50px",
//                 cursor: "pointer",
//               }}
//             />
//           </div>
        
//       ))} */}
//       <label htmlFor="productList">Select Product: </label>
//       <select name="productList" id="productList" onChange={handleChange}>
//       <option style={{display:'none'}}>--Select--</option>
//         {productData.map(product => (
//           <option 
//           value= {product.brand_product_masters[0].product_uid}
//           >
//             <span>
//             <img
//               src={product.brand_product_masters[0].product_image}
//               style={{
//                 width: "25px",
//                 height: "25px",
//                 cursor: "pointer",
//               }}
//             />
//             </span>
//             <span>
//               <h2>{product.brand_product_masters[0].name}</h2>
//             </span>
//           </option>
//         ))}
//       </select>
//       <label htmlFor="productPosition">Select Position: </label>
//       <select name="productPosition" id="productPosition" onChange={handleChange}>
//         <option style={{display:'none'}}>--Select--</option>
//         <option value="location1">Location 1</option>
//         <option value="location2">Location 2</option>
//         <option value="location3">Location 3</option>
//         <option value="location4">Location 4</option>
//         <option value="location5">Location 5</option>
//       </select>
//       <button onClick={handleSubmit}>Apply</button>
//     </div>
//     </>
//   );
// }

// export default Box;

import React, { useState } from "react";
import useModelStore from '../store';

function Box({ productData, onApply }) {
  const [formValues, setFormValues] = useState([]);
  const [name,changeName] = useState('');
  const [location,setLocation] = useState('');
  const setModelPosition = useModelStore((state) => state.setModelPosition);

  // Handle product selection
  const handleProductChange = (product_uid) => {
    setFormValues((prevValues) => {
      // Check if the product already exists in the formValues
      const existingProduct = prevValues.find(
        (item) => item.product_uid === product_uid
      );
      if (!existingProduct) {
        // Add new product with an empty position
        return [...prevValues, { product_uid, productPosition: "" }];
      }
      return prevValues; // If the product already exists, do nothing for now
    });
    changeName(product_uid);
  };

  // Handle position selection
  const handlePositionChange = (position) => {
    setFormValues((prevValues) =>
      prevValues.map((item) =>
        item.product_uid === selectedProduct
          ? { ...item, productPosition: position }
          : item
      )
    );
    setLocation(position);
  };

  // Handle product selection from dropdown
  const [selectedProduct, setSelectedProduct] = useState("");

  const getPositionFromLocation = (location) => {
    switch (location) {
      case "location1":
        return [-4.891337813128718, 2, -5.720227980977997];
      case "location2":
        return [1.9916866740908432, 2, 13.23984951053118];
      case "location3":
        return [-5.953889676150517, 2, 21.4005676390457];
      case "location4":
        return [-30.49039677915537, 2, 13.560054539298182];
      case "location5":
        return [-26.521714223481858, 2, -7.335591221166235];
      default:
        return [0, 0, 0]; // Default position if no location is matched
    }
  };

  const handleSubmit = () => {
    onApply(formValues);
    console.log(name+' '+getPositionFromLocation(location));
    if(name.length){
      setModelPosition(name, getPositionFromLocation(location));
    }
    
  };

  return (
    <div
      style={{
        position: "absolute",
        bottom: "0",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        gap: "10px",
        padding: "13px",
        borderRadius: "5px",
        background: "#fff",
      }}
    >
      {/* Product Selection */}
      <select
        value={selectedProduct}
        onChange={(e) => {
          setSelectedProduct(e.target.value);
          handleProductChange(e.target.value);
        }}
      >
        <option value="">--Select Product--</option>
      
        {productData.map((product) => (
          <option
            key={product.product_uid}
            value={product.product_uid}
          >
            {product.name}
          </option>

          
        ))}
      </select>

      {/* Position Selection */}
      <select
        value={
          formValues.find((item) => item.product_uid === selectedProduct)
            ?.productPosition || ""
        }
        onChange={(e) => handlePositionChange(e.target.value)}
      >
        <option value="">--Select Position--</option>
        <option value="location1">Location 1</option>
        <option value="location2">Location 2</option>
        <option value="location3">Location 3</option>
        <option value="location4">Location 4</option>
        <option value="location5">Location 5</option>
      </select>

      <button onClick={handleSubmit}>Apply</button>
    </div>
  );
}

export default Box;
