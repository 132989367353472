import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import PlayAround from "./pages/PlayAround";
import PlayAround2 from "./pages/PlayAround2";
import "./fonts/inter/Inter-VariableFont.ttf";
import "./App.scss";
import "./pages/Layout.scss";
import Loader from "./components/Loader";
import { Suspense } from "react";
import Login from "./components/login-signup/Login";
import Signup from "./components/login-signup/Signup";
import HeaderContextProvider from "./store/Header-context";
import EmailVerification from "./components/login-signup/EmailVerification";
import AuthGuard from "./components/AuthGuard";
import Dashboard from "./components/Dashboard";
import Profile from "./components/Profile";
import Homepage from "./components/Homepage";
import ResetPassword from "./components/ResetPassword";
import CreatePassword from "./components/login-signup/CreatePassword";
import PasswordResetSuccess from "./components/login-signup/PasswordResetSuccess";
import PlayAround3 from "./pages/PlayAround3";
import SelectEnv from "./components/addProducts/SelectEnv";
import EnvStore from "./components/addProducts/EnvStore";
import AddProducts from "./components/addProducts/AddProducts";
import ThreeScreen from "./components/addProducts/ThreeScene"
import Game from "./pages/Game";
import CompanyProfile from "./components/CompanyProfile";
import PaymentSubmission from "./components/PaymentSubmission";
import Subscription from "./components/Subscription";
import SpinTheWheel from "./pages/SpinTheWheel";
import QuizGame from "./pages/QuizGame";
import Launch from "./components/Launch";
import Congratulations from "./components/Congratulations";
import Env from "./components/client/Env";
import CouponCodeQuiz from "./pages/CouponCodeQuiz";
import AccountCreated from "./components/AccountCreated";
import PasswordVerification from "./components/login-signup/PasswordVerification";
import Refresh from "./components/addProducts/Refresh";
import ComingSoon from "./components/ComingSoon";
import Testing from "./components/addProducts/Testing";
import CustomProduct from "./pages/CustomProduct";
import CustomEnv from "./pages/CustomEnv";
import ProductAnalytic from "./pages/ProductAnalytic";
import Analytics from "./pages/Analytics";
import ActiveGame from "./pages/ActiveGame";
import Enviroment from "./pages/Enviroment";
import CustomizeEnv from "./pages/CustomizeEnv";
import CustomizeEnv2 from "./pages/CustomizeEnv2";
import CustomProduct2 from "./pages/CustomProduct2";
import CustomizeEnv3 from "./pages/CustomizeEnv3";
import Home2 from "./Home2";
import Home3 from "./Home3";
import TestComp from "./components/TestComp";
import GabarCustomEnv from "./gabar/GabarCustomEnv";
import GabarProduct from "./gabar/GabarProduct";

function App() {
 

  return (
    <HeaderContextProvider>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />

              {/* Login pages */}
              <Route path="signup" element={<Signup />} />
              <Route path="Email-verification" element={<EmailVerification />} /> 
              <Route path='verify-email/*' element={<ResetPassword/>} />
              <Route path='account-created' element={<AccountCreated/>} />
              <Route path="login/*" element={<Login />} />

              {/*Forgot password*/}
              <Route path="password-verification" element={<PasswordVerification />} />
              <Route path='set-password/*' element={<ResetPassword/>} />
              <Route path="resetsuccess" element={<PasswordResetSuccess/>} />

              {/*Dashboad*/}
              <Route path="dashboard" element={<AuthGuard><Dashboard /></AuthGuard>}>
                <Route path="" element={<AuthGuard><Homepage /></AuthGuard>}/>
                <Route path="company-profile" element={<AuthGuard><CompanyProfile/></AuthGuard>}></Route>
                <Route path="subscription" element={<AuthGuard><Subscription/></AuthGuard>} />
                <Route path="coming-soon" element={<AuthGuard><ComingSoon/></AuthGuard>} />

                {/*Add store and products*/}
                <Route path="select-store" element={<AuthGuard><EnvStore/></AuthGuard>}></Route>
                <Route path="add-products" element={<AuthGuard><AddProducts/></AuthGuard>}></Route> 

                {/*Add Game*/}
                <Route path="add-game" element={<AuthGuard><Game/></AuthGuard>}></Route>
                {/*Quiz*/}
                <Route path="add-coupon-code" element={<AuthGuard><CouponCodeQuiz/></AuthGuard>}/>  
                <Route path="quiz" element={<AuthGuard><QuizGame/></AuthGuard>} />
                {/*Spin*/}  
                <Route path="spin" element={<AuthGuard><SpinTheWheel/></AuthGuard>} /> 

                {/*After Launch*/}
                <Route path="enviroment" element={<AuthGuard><Enviroment/></AuthGuard>}></Route>
                <Route path="product-analytics" element={<AuthGuard><ProductAnalytic/></AuthGuard>}></Route> 
                <Route path="game" element={<AuthGuard><ActiveGame/></AuthGuard>}></Route> 
                <Route path="analytics" element={<AuthGuard><Analytics/></AuthGuard>}></Route>
              </Route>

              {/*Select ENV*/}
              <Route path="select-enviroment" element={<AuthGuard><SelectEnv/></AuthGuard>}></Route>

              {/* Customize ENV Campaign and Videos*/}
              <Route path="playarea/*" element={<AuthGuard><CustomizeEnv/></AuthGuard>}></Route>  
              <Route path="customize-env/*" element={<AuthGuard><CustomProduct/></AuthGuard>}></Route>

              {/*Launching*/}
              <Route path="launch" element={<AuthGuard><Launch/></AuthGuard>}></Route>         
              <Route path="/:companyName/playAround/*" element={<Env/>}/>

              {/*Additional Pages*/}
              <Route path="resetpassword" element={<AuthGuard><ResetPassword /></AuthGuard>} />             
              <Route path="payment-form" element={<AuthGuard><PaymentSubmission/></AuthGuard>}/>


              {/*Testing pages*/}
              <Route path="testing" element={<Testing/>} /> 
              <Route path="3d-image/*" element={<CustomizeEnv2/>}></Route>
              <Route path="env-testing/*" element={<CustomizeEnv3/>}></Route>
              <Route path="customize-envs/*" element={<AuthGuard><CustomProduct2/></AuthGuard>}></Route>

              {/*Gabar*/}
              <Route path="gabar-addProducts" element={<AuthGuard><Home2/></AuthGuard>}></Route>
              {/* <Route path="customize-gabar-env" element={<AuthGuard><Home3/></AuthGuard>}></Route>*/}
              <Route path="customize-gabar-env" element={<AuthGuard><GabarCustomEnv/></AuthGuard>}></Route> 
              <Route path="customize-gabar-product" element={<AuthGuard><GabarProduct/></AuthGuard>}></Route>   

              {/*phase1.0 not needed*/
                // <Route path="play-around" element={<PlayAround/>} />
              }

             <Route path="*" element={<Home />}></Route>
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </HeaderContextProvider>
  );
}

export default App;
