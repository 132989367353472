import React from "react";
import Moleculers from "./Moleculers";


function Molecules1({ productData, showProduct, hasToken=true}) {
  return (
    <>
      {productData.map(product => (
        <Moleculers
          key={product.product_uid}
          url={product.product_3D_model_url}
          name={product.product_uid}
          scale={[5,5,5]} 
          showProduct={showProduct}
          hasToken={hasToken}
          productData= {product}
        />
      ))}
    </>
  );
}

export default Molecules1;
