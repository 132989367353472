import React, { Suspense, useEffect, useCallback, useRef } from "react";
import { useGLTF, useTexture, useVideoTexture } from "@react-three/drei";
import { Color, DoubleSide, SRGBColorSpace ,Raycaster, Vector2} from "three";
import ReactGA from "react-ga4";
import { RigidBody } from "@react-three/rapier";
import useUIStore from "../../utils2/uistore";
import { Info_Click } from "./Info_Click";
import useStore from "../../utils2/3dstore";
import { useFrame, useThree } from "@react-three/fiber";
import useAnalytics from "../../utils2/google-analytics/useGTAGTracker";
import { Product_Click } from "./Product_Click";

export function Building_Products(props) {
  const { camera } = useThree();
  const liquidColor = new Color("#927947");
  const newColor = new Color("#AAAAAB");
  const pointColor = new Color("#8EA992");
  const { nodes, materials } = useGLTF("/assets/models/building_products.glb");

      // Create refs for raycaster and mouse
      const mouse = new Vector2();
      const raycaster = new Raycaster();

  const {
    setHitPoint,
    setDoubleClick,
    isProductClick,
    setProductClick,
    isCameraPanning,
    setLcdCapsuleHit,
    isEntryAnimationDone,
    isRotateProducts,
    setRotateProducts,
  } = useStore();
  const floatBottleRef = useRef();
  const groundBottleRef = useRef();
  const bodyOilBottleRef = useRef();
  const faceOilBottleRef = useRef();
  const swimBottleRef = useRef();
  const {
    handleShowProductList,
    handleItems,
    getData,
    productList,
    handleCurrentItem,
    handleActiveImage,
  } = useUIStore();

  const { track3DCapsuleView } = useAnalytics();
 

  const actionMap = {
    floatBottleClick: {
      productClick: true,
      currentItem: productList[2],
    },
    swimBottleClick: {
      productClick: true,
      currentItem: productList[6],
    },
    bodyOilBottleClick: {
      productClick: true,
      currentItem: productList[0],
    },
    groundBottleClick: {
      productClick: true,
      currentItem: productList[4],
    },
    faceOilBottleClick: {
      productClick: true,
      currentItem: productList[1],
    },
    main_wall: {
      doubleClick: true,
    },
    lcd_capsule: {
      doubleClick: true,
      lcdCapsuleHit: true,
    },
  };

  const sendDropLocationToParent = useCallback(    
    (location) => {
     
      if (props.onDropLocation) {
        props.onDropLocation(location);
      }
    },
    [props.onDropLocation] // Dependencies
  );

  useEffect(() => {
    const handleMouseMove = (e) => {
      mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
  
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObject(nodes.Floor, true);
      intersects.map((intersect) => (
        console.log(intersect)
        
      ))
      const abcIntersects = intersects.filter((intersect) =>
        (intersect.object.name.includes("product-drop") || intersect.object.material.name.includes("blue-seats") || intersect.object.material.name.includes("tables"))
      );
      // console.log(nodes);
      console.log(abcIntersects?'yes':'no');
      
      if (intersects.length > 0) {
        const point = intersects[intersects.length -1].point;
        // console.log(point);
        sendDropLocationToParent(point);
      }
    };
  
    // Add mouse move event listener
    window.addEventListener("dragend", handleMouseMove);
  
    return () => {
      window.removeEventListener("dragend", handleMouseMove);
    };
  }, [camera, nodes.Floor]);

  const animateCamera = (e) => {
    // console.log("PRODUCT CLICKED")
    // console.log(e.object.name);
    console.log(`Click Position - X: ${e.point.x}, Y: ${e.point.y}, Z: ${e.point.z}`);
    e.stopPropagation();





    const action = actionMap[e.object.name];
    if (action) {
      setHitPoint(e.point);
      if (!isCameraPanning) {
        if (action.customAction) action.customAction();

        if (action.productClick) {
          setProductClick(true);
          track3DCapsuleView(action.currentItem.title);
          handleCurrentItem(action.currentItem);
          handleActiveImage(
            action.currentItem?.images?.map((el, index) => (
              <div className="zoomed-image" key={index}>
                <img
                  src={el}
                  className="main-image"
                  alt={`Zoomed Image ${index}`}
                />
              </div>
            ))
          );
        }

        if (action.doubleClick) setDoubleClick(true);

        if (action.lcdCapsuleHit) setLcdCapsuleHit(true);
        // console.log(isProductClick);
      }
    }
  };


  useEffect(() => {
    if (isEntryAnimationDone)
      setTimeout(() => {
        setRotateProducts(true);
      }, 5000);
  }, [isEntryAnimationDone]);

  useFrame(() => {
    if (!floatBottleRef.current) return;
    if (!groundBottleRef.current) return;
    if (!bodyOilBottleRef.current) return;
    if (!faceOilBottleRef.current) return;
    if (!swimBottleRef.current) return;

    if (isRotateProducts) {
      floatBottleRef.current.rotation.y += 0.01;
      groundBottleRef.current.rotation.y += 0.01;
      bodyOilBottleRef.current.rotation.y += 0.01;
      faceOilBottleRef.current.rotation.y += 0.01;
      swimBottleRef.current.rotation.y += 0.01;
    }
  });

  const GTAG3DCapsuleView = (item_name) => {
    // console.log("Product Capsule View")
    ReactGA.gtag("event", "Product Capsule View", {
      product_capsule_view: item_name,
    });
  };
  return (
    <>
      <RigidBody type="fixed" colliders="trimesh">     
        <group {...props} dispose={null}>
          <mesh
            geometry={nodes.LCD_Capsule_Holder.geometry}
            material={materials.LCD_Holder_Material}
            position={[-15.122, 14.528, -9.585]}
          />
         

          {/* CHANGES -> Mesh003 */}
          <group
            position={[-15.039, 2.351, -4.902]}
            name="lcd_capsule"
            onClick={(e) => animateCamera(e)}
            onDoubleClick={(e) => animateCamera(e)}
          >
            <mesh
              geometry={nodes.Mesh011.geometry}
              material={materials.LCD_Capsule_Material}
            />
            <mesh
              geometry={nodes.Mesh011_1.geometry}
              material={materials.LCD_Capsule_Belt_Material}
            />
            <mesh
              geometry={nodes.Mesh011_2.geometry}
              material={materials.LCD_FrontTop_Material}
              position={[0, 1.58, 0]}
              scale={[1, 0.75, 1]}
            >
              <Suspense
                fallback={
                  <FallbackMaterial url="/assets/videos/metaverse_2_Top.jpg" />
                }
              >
                <VideoMaterial url="/assets/videos/Metaverse_2 _Top_Optimised_1.mp4" />
              </Suspense>
            </mesh>
            <mesh
              geometry={nodes.Mesh011_3.geometry}
              material={materials.LCD_FrontTop_Material}
            >
              <Suspense
                fallback={
                  <FallbackMaterial url="/assets/videos/metaverse_1_Bottom.jpg" />
                }
              >
                <VideoMaterial url="/assets/videos/Metaverse_1_Bottom_Optimised_1.mp4" />
              </Suspense>
            </mesh>
            <mesh
              geometry={nodes.Mesh011_4.geometry}
              material={materials.LCD_FrontTop_Material}
              position={[0, 1.58, 0]}
              scale={[1, 0.75, 1]}
            >
              <Suspense
                fallback={
                  <FallbackMaterial url="/assets/videos/metaverse_2_Top.jpg" />
                }
              >
                <VideoMaterial url="/assets/videos/Metaverse_2 _Top_Optimised_1.mp4" />
              </Suspense>
            </mesh>
            <mesh
              geometry={nodes.Mesh011_5.geometry}
              material={materials.LCD_FrontTop_Material}
            >
              <Suspense
                fallback={
                  <FallbackMaterial url="/assets/videos/metaverse_1_Bottom.jpg" />
                }
              >
                <VideoMaterial url="/assets/videos/Metaverse_1_Bottom_Optimised_1.mp4" />
              </Suspense>
            </mesh>
          </group>
          {/* CHANGES -> The building and floor were separated into 2 meshes, Should be joined */}
          <mesh
            name="main_wall"
            onDoubleClick={(e) => animateCamera(e)}
            geometry={nodes.Floor.geometry}
            material={materials.Metallic_Silver_Material}
            position={[-14.04, -0.001, -4.603]}
            material-roughness={0.35}
            material-metalness={0.75}
            material-envMapIntensity={1.5}
            material-color={newColor}
          />
          {/* CHANGES -> Product_Capsule_Glass */}
          {/* <mesh
            geometry={nodes.Glass_Capsules.geometry}
            material={materials.LCD_Capsule_GlassCover_Material}
            position={[-1.845, 0.201, 5.678]}
            material-roughness={0.05}
            material-metalness={0.85}
            material-specularColor={[1.77, 1.77, 1.77]}
            material-depthWrite={false}
          /> */}
          {/* <mesh
            ref={bodyOilBottleRef}
            scale={[0.9, 0.9, 0.9]}
            geometry={nodes.Body_Oil_Bottle001.geometry}
            material={materials["BodyOilBottle_Base.001"]}
            position={[0.754, 2.075, -13.678]}
            rotation={[0, 1.4, 0]}
          />
          <mesh
            ref={faceOilBottleRef}
            geometry={nodes.Face_Oil_Bottle001.geometry}
            material={materials["FaceOilBottle_Base.001"]}
            position={[-31.525, 1.986, -10.689]}
            rotation={[0, 0.5, 0]}
          /> */}
          {/* <group position={[-22.767, 2.291, 8.069]} ref={floatBottleRef}>
            <mesh
              geometry={nodes.PerfumeBottle_Base005.geometry}
              material={materials.LCD_Capsule_GlassCover_Material}
            />
            <mesh
              geometry={nodes.PerfumeBottle_Base005_1.geometry}
              material={materials["PerfumeBottle_Lid.005"]}
            />
            <mesh
              geometry={nodes.PerfumeBottle_Base005_2.geometry}
              material={materials["PerfumeBottle_Label_Float.001"]}
            />
            <mesh
              geometry={nodes.PerfumeBottle_Base005_3.geometry}
              material={materials["Perfume_Liquid.005"]}
              material-side={DoubleSide}
              material-color={liquidColor}
              material-roughness={0.2}
              material-metalness={0.2}
              material-opacity={0.3}
              transparent
            /> 
          </group> */}
          <group position={[-24.184, 2.235, -19.347]} ref={groundBottleRef}>
            {/* <mesh
              geometry={nodes.PerfumeBottle_Base004.geometry}
              material={materials.LCD_Capsule_GlassCover_Material}
            />
            <mesh
              geometry={nodes.PerfumeBottle_Base004_1.geometry}
              material={materials["PerfumeBottle_Lid.005"]}
            />
            <mesh
              geometry={nodes.PerfumeBottle_Base004_2.geometry}
              material={materials["PerfumeBottle_Label_Ground.001"]}
            />
            <mesh
              geometry={nodes.PerfumeBottle_Base004_3.geometry}
              material={materials["Perfume_Liquid.005"]}
              material-side={DoubleSide}
              material-color={liquidColor}
              material-roughness={0.2}
              material-metalness={0.2}
              material-opacity={0.3}
              transparent
            /> */}
          </group>
          <group position={[-1.569, 2.172, 7.531]} ref={swimBottleRef}>
            {/* <mesh
              geometry={nodes.PerfumeBottle_Base003.geometry}
              material={materials.LCD_Capsule_GlassCover_Material}
            />
            <mesh
              geometry={nodes.PerfumeBottle_Base003_1.geometry}
              material={materials["PerfumeBottle_Lid.005"]}
            />
            <mesh
              geometry={nodes.PerfumeBottle_Base003_2.geometry}
              material={materials["PerfumeBottle_Label_Swim.001"]}
            />
            <mesh
              geometry={nodes.PerfumeBottle_Base003_3.geometry}
              material={materials["Perfume_Liquid.005"]}
              material-side={DoubleSide}
              material-color={liquidColor}
              material-roughness={0.2}
              material-metalness={0.2}
              material-opacity={0.3}
              transparent
            /> */}
          </group>
        </group>
      </RigidBody>
      {/* <group>
        <Product_Click
          name="bodyOilBottleClick"
          onClick={(e) => animateCamera(e)}
          onDoubleClick={(e) => {
            animateCamera(e);
          }}
          position={[0.765, 2.39, -13.701]}
        />
        <Product_Click
          name="groundBottleClick"
          onClick={(e) => animateCamera(e)}
          onDoubleClick={(e) => {
            animateCamera(e);
          }}
          position={[-24.197, 2.39, -19.356]}
        />
        <Product_Click
          name="faceOilBottleClick"
          onClick={(e) => animateCamera(e)}
          onDoubleClick={(e) => {
            animateCamera(e);
          }}
          position={[-31.52, 2.39, -10.701]}
        />
        <Product_Click
          name="swimBottleClick"
          onClick={(e) => animateCamera(e)}
          onDoubleClick={(e) => {
            animateCamera(e);
          }}
          position={[-1.471, 2.39, 7.527]}
        />
        <Product_Click
          name="floatBottleClick"
          onClick={(e) => animateCamera(e)}
          onDoubleClick={(e) => {
            animateCamera(e);
          }}
          position={[-22.757, 2.39, 8.039]}
        />
      </group> */}
      <group dispose={null}>
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[0.765, 0.532, -13.701]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[-24.197, 0.54, -19.356]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[-31.52, 0.54, -10.701]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[-1.471, 0.54, 7.527]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[-22.757, 0.54, 8.039]}
        />
      </group>
      {/* <Info_Click
        name={"buyFaceOilBottle"}
        position={[-30.9, 4.8, -10.714]}
        clickPosition={[-31.52, 5.174, -10.701]}
        currentItem={productList[1]}
      /> */}
      {/* <Info_Click
        name={"buySwimBottle"}
        position={[-1.2, 4.9, 7.527]}
        clickPosition={[-1.471, 5.174, 7.527]}
        currentItem={productList[6]}
      />
      <Info_Click
        name={"buyFloatBottle"}
        position={[-22, 4.9, 8.039]}
        clickPosition={[-22.757, 5.174, 8.039]}
        currentItem={productList[2]}
      />
      <Info_Click
        name={"buygroundBottle"}
        position={[-23.7, 4.9, -20.116]}
        clickPosition={[-24.197, 5.174, -19.356]}
        currentItem={productList[4]}
      />
      <Info_Click
        name={"buyFaceOilBottle"}
        position={[-30.9, 4.8, -10.714]}
        clickPosition={[-31.52, 5.174, -10.701]}
        currentItem={productList[1]}
      />
      <Info_Click
        name={"buyBodyOilBottle"}
        position={[1.6, 5.2, -13.689]}
        clickPosition={[0.765, 5.174, -13.701]}
        currentItem={productList[0]}
      /> */}
      <mesh
        geometry={nodes.Walkway_Path.geometry}
        material={materials.Walkway_Material}
        position={[-14.763, 0.288, -4.851]}
        material-roughness={0.1}
        material-metalness={1}
        material-envMapIntensity={1}
      />
    </>
  );
}

function VideoMaterial({ url }) {
  const texture = useVideoTexture(url);
  texture.flipY = false;
  texture.colorSpace = SRGBColorSpace;
  // console.log(texture.flipY)
  return <meshBasicMaterial map={texture} toneMapped={false} />;
}

function FallbackMaterial({ url }) {
  const texture = useTexture(url);
  texture.flipY = false;
  texture.colorSpace = SRGBColorSpace;
  // console.log(texture.flipY)
  return <meshBasicMaterial map={texture} toneMapped={false} />;
}

useGLTF.preload("/assets/models/building_products.glb");
