import "./ui.css";
import "./font/Univers-light-normal.ttf";
import "./font/UniversBlack.ttf";
import "./font/UniversLTStd.otf";
import "./font/UniversLTStd-Bold.otf";
import axios from "axios";
import useUIStore from "../../utils2/uistore";
import Product from "./Product/Product";
import Header from "./Header/Header";
import ProductList from "./ProductList/ProductList";
import ThankYou from "./ThankYou/ThankYou";
import Instruction from "./Instruction/Instruction";
import Cart from "./ShoppingCart/Cart";
import TradeMark from "./TradeMark/TradeMark";
import { useEffect, useState } from "react";
import useShopifyStore from "../../utils2/shopifyStore";
import { Player, SoundOnOff } from "./Audio";
import Game_Popups from "../Game/Game";
import KeyboardIcon from "./KeyboardIcon/KeyboardIcon";
import ViewportHeightSetter from "../../utils2/useViewportHeightSetter";
import Header2 from '../../components/Header';

export default function UI() {
  const {
    showCart,
    setCartLoaded,
    setCartOpened,
    setCartChecked,
    count,
    increase,
    getProductData,
    showItems,
    setActiveImageIndex,
    setShowActiveImage,
  } = useUIStore((state) => state);
  const { setProductList, cartItems, setCartItems, checkOutId, setCheckOutId } =
    useShopifyStore();

  const [quantity, setQuantity] = useState(count);
  const [counter, setCounter] = useState(count);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        "https://gabar-prod.onewayx.world/products"
      );
      // console.log(response.data, "API Response");

      /////added all products to useShopifyStore Object
      setProductList(response.data);
      // getProductData(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    setCheckOutId(localStorage.getItem("checkoutId"));
    fetchProducts();
    getCartItem();
  }, []);
  const updateCount = (newCount) => {
    setCounter(newCount);
    increase(newCount);
  };

  useEffect(() => {
    if (!showCart) {
      setCartLoaded(false);
      setCartChecked(false);
      setCartOpened(false);
    }
  }, [showCart]);

  useEffect(() => {
    if (!showItems) {
      setActiveImageIndex(0);
      setShowActiveImage(false);
    }
  }, [showItems]);

  const getCartItem = async () => {
    try {
      let totalCount = 0;
      let checkoutid = localStorage.getItem("checkoutId");
      if (checkoutid !== null) {
        const response = await axios.post(
          "https://gabar-prod.onewayx.world/cart",
          {
            checkoutId: checkoutid,
          }
        );
        // console.log("RAN");
        const cartList = response.data.cartItems;
        cartList?.forEach((cartItem) => {
          totalCount += cartItem.quantity;
        });
        increase(totalCount);
        setCounter(response.data.counter);
        setCartItems(response.data);
        increase(totalCount);
      }
      if (showCart) {
        // console.log("RESOLVING CART COMPLETED")
        setCartLoaded(true);
        setCartChecked(true);
      }
      // console.log("REACHING");
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  return (
    <>
      <div className="no-text-select">
        <ViewportHeightSetter />
        <Header2 variation={5}/>
        {/* <Header />
        <ProductList /> */}
        <Instruction getCartItem={getCartItem} />
        {/* <Cart
          getCartItem={getCartItem}
          count={counter}
          updateCount={updateCount}
          increase={increase}
        />
        <Product getCartItem={getCartItem} />
        <ThankYou /> */}
        <Player />
        <TradeMark />
        <KeyboardIcon />
        <SoundOnOff />
      </div>
      {/* <Game_Popups /> */}
    </>
  );
}
