import React from "react";
import "../header.css";
import useUIStore from "../../../../utils2/uistore";
function ShoppingBag() {
  const { instructionModal } = useUIStore();
  opacity: !instructionModal ? 0 : 1;
  return (
    <div
      className="shopping-bag-img hide-selection"
      style={{
        height: "1.5rem",
        width: "1.5rem",
        opacity: !instructionModal ? 0 : 1,
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" fill="none">
        <path
          d="M22.2167 6.49578C22.1908 6.33092 22.1064 6.18089 21.979 6.07318C21.8515 5.96547 21.6895 5.90729 21.5226 5.90931H16.6643V4.52122C16.6643 3.41678 16.2255 2.35758 15.4446 1.57662C14.6636 0.795669 13.6044 0.356934 12.5 0.356934C11.3956 0.356934 10.3364 0.795669 9.55541 1.57662C8.77445 2.35758 8.33572 3.41678 8.33572 4.52122V5.90931H3.47739C3.31052 5.90729 3.14852 5.96547 3.02105 6.07318C2.89359 6.18089 2.8092 6.33092 2.78334 6.49578L0.00715436 24.194C-0.00726338 24.2936 0.000167139 24.3952 0.0289305 24.4917C0.0576938 24.5881 0.107101 24.6772 0.173726 24.7527C0.238887 24.8289 0.319779 24.89 0.410836 24.932C0.501893 24.9739 0.600953 24.9956 0.701201 24.9956H24.2988C24.399 24.9956 24.4981 24.9739 24.5892 24.932C24.6802 24.89 24.7611 24.8289 24.8263 24.7527C24.8929 24.6772 24.9423 24.5881 24.9711 24.4917C24.9998 24.3952 25.0073 24.2936 24.9928 24.194L22.2167 6.49578ZM9.72381 4.52122C9.72381 3.78492 10.0163 3.07879 10.5369 2.55815C11.0576 2.03752 11.7637 1.74503 12.5 1.74503C13.2363 1.74503 13.9424 2.03752 14.4631 2.55815C14.9837 3.07879 15.2762 3.78492 15.2762 4.52122V5.90931H9.72381V4.52122ZM1.51324 23.6075L4.0708 7.2974H8.33572V8.6855C8.33572 8.86957 8.40884 9.0461 8.539 9.17626C8.66916 9.30642 8.84569 9.37954 9.02977 9.37954C9.21384 9.37954 9.39037 9.30642 9.52053 9.17626C9.65069 9.0461 9.72381 8.86957 9.72381 8.6855V7.2974H15.2762V8.6855C15.2762 8.86957 15.3493 9.0461 15.4795 9.17626C15.6096 9.30642 15.7862 9.37954 15.9702 9.37954C16.1543 9.37954 16.3308 9.30642 16.461 9.17626C16.5912 9.0461 16.6643 8.86957 16.6643 8.6855V7.2974H20.9292L23.4868 23.6075H1.51324Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

export default ShoppingBag;
