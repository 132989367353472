// import React from 'react';
// import PlayAround2 from '../../pages/PlayAround2';

// function Env() {
//   return (
//     <>
//       <PlayAround2/>
//     </>
//   )
// }

// export default Env

import React, { useEffect, useState } from "react";
import { fetchData } from "../../service/http.service";
import ApiConfig from "../../config/ApiConfig";
import Gabar from "../../gabar/Gabar";

export default function Env() {
  const [loading, setLoading] = useState(true);
  const [audioFile, setAudioFile] = useState(null);
  const [videoFile, setVideoile] = useState(null);
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    async function envDetails() {
      setLoading(true);
      const url = window.location.href;
      const parts = url.split("/");
      const data = parts[parts.length - 1];
      const response = await fetchData(
        ApiConfig.brandFileMapping,
        setLoading,
        "post",
        {
          brand_param: data,
        },
        {}
      );

      if (response?.status === 200) {        
        setVideoile(
          response?.data?.data?.video_data[
            (response?.data?.data?.video_data?.length - 1 || 0)
          ]?.video_url || ''
        );
        setAudioFile(
          response?.data?.data?.audio_data[
            (response?.data?.data?.audio_data?.length - 1 || 0)
          ]?.audio_url || ''
        );
        setProductData(response?.data?.data?.product_result || '');        
      }
      setLoading(false);
    }
    envDetails();
  }, []);
  return <>
  {!loading && <Gabar audioFile={audioFile} videoFile={videoFile} productData={productData}/>}
  </>;
}
