import React, { useEffect } from "react";
import useSound from "use-sound";
import "./instruction.css";
import useUIStore from "../../../utils2/uistore";
import useStore from "../../../utils2/3dstore";
import CloseIcon from "@mui/icons-material/Close";
import useShopifyStore from "../../../utils2/shopifyStore";
import {
  isMobile,
  isTablet,
  isAndroid,
  isIOS,
  isMobileOnly,
  isDesktop,
} from "react-device-detect";
import useSoundStore from "../Audio/soundStore";
import useDeviceDetect from "../../../utils2/useDeviceDetect";

export default function Instruction() {
  const isTouchDevice = useDeviceDetect();
  const soundUrl = "/assets/audio/sound-aac.aac";
  const [play, { stop, isPlaying }] = useSound(soundUrl, { loop: true });
  const { handleInstructionModal, instructionModal } = useUIStore(
    (state) => state
  );
  const { setPlaying } = useSoundStore();
  const { checkOutId } = useShopifyStore();

  const {
    isLoadingDone,
    isReady,
    setReady,
    isEntryAnimationDone,
    setDoneEntryAnimation,
    setPanEnabled,
  } = useStore();

  const instructionClassName = `parent-container-dummy ${
    isLoadingDone ? "show_screen" : "noshow_screen"
  }`;
  const insDivisionClassName = `ins-division ${
    isLoadingDone ? "show_screen" : "noshow_screen"
  }`;

  const startJourney = () => {
    setPlaying(true);
    handleInstructionModal(true);
    setPanEnabled(false);
    setReady(true);
    if (isEntryAnimationDone === undefined) setDoneEntryAnimation(false);
  };
  return (
    <>
      {!instructionModal ? (
        <div className={instructionClassName}>
          <div className={insDivisionClassName}>
            <div className="heading-form_0">Welcome to Gabar</div>
            <div className="heading-form">
              Instructions To Navigate Our New World
            </div>
            <div className="ins-sub-container">
              <div className="ins-item-container">
                <img
                  className="ins-img2"
                  src={
                    isTouchDevice
                      ? "/assets2/images/joystick.svg"
                      : "/assets2/images/wasd.svg"
                  }
                />
                <div className="ins-partition" />
                {isTouchDevice ? (
                  <span className="ins-light-txt-mouse">
                    Use joystick to move around the space
                  </span>
                ) : (
                  <div className="ins-txt-sub-container">
                    <div className="ins-txt">
                      <span className="ins-bold-txt">W</span><span className="ins-bold-txt" style={{marginLeft:"1px"}}>:</span>
                      <span className="ins-light-txt"> Move forward</span>
                    </div>
                    <div className="ins-txt">
                      <span className="ins-bold-txt">A</span><span className="ins-bold-txt" style={{marginLeft:"4px"}}>:</span>
                      <span className="ins-light-txt"> Move left</span>
                    </div>
                    <div className="ins-txt">
                      <span className="ins-bold-txt">S</span><span className="ins-bold-txt" style={{marginLeft:"5px"}}>:</span>
                      <span className="ins-light-txt"> Move backward</span>
                    </div>
                    <div className="ins-txt">
                      <span className="ins-bold-txt">D</span>
                      <span className="ins-bold-txt" style={{marginLeft:"4px"}}>:</span>
                      <span className="ins-light-txt"> {" "}Move right</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="ins-border" />
              <div className="ins-item-container">
                <img
                  className="ins-img"
                  src={
                    isTouchDevice
                      ? "/assets/images/finger-1.svg"
                      : "/assets/images/mouse-1.png"
                  }
                />
                <div className="ins-partition" />
                <span className="ins-light-txt-mouse">
                  Click and drag to look around the space
                </span>
              </div>
              <div className="ins-border" />
              <div className="ins-item-container">
                <img
                  className="ins-img"
                  src={
                    isTouchDevice
                      ? "/assets/images/finger-2.svg"
                      : "/assets/images/mouse-2.png"
                  }
                />
                <div className="ins-partition" />
                <span className="ins-light-txt-mouse">
                  Double click to move around the space
                </span>
              </div>
            </div>
            <input
              onClick={startJourney}
              className="styled no-text-select"
              type="button"
              value="Enter"
            />
          </div>
        </div>
      
      ) : (
        ""
      )}
    </>
  );
}
