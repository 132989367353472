// import "./Home2.css";
// import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import Experience from "./components2/Experience";
// import Loading from "./components2/UI/Loading/Loader";
// import Landscape from "./components2/UI/Landscape/Landscape";
// import {
//   EnterFullScreenButton,
//   ExitFullScreenButton,
// } from "./components2/Controls/FullScreen";
// import UI from "./components2/UI/UI";

// export default function Gabar() {

//     const handle = useFullScreenHandle();

//     return (
//             <>
//       <div className="App">
//         <div className="ReactFullScreen">
//           <EnterFullScreenButton handle={handle} />
//           <FullScreen handle={handle}>
//             <div>
//               <ExitFullScreenButton handle={handle} />
//               <UI />
//               <Experience />
//             </div>
//           </FullScreen>
//         </div>
//       </div>
//       <Loading />
//       <Landscape />
//     </>
//     )
// }

import "./Home2.css";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Loading from "./components2/UI/Loading/Loader";
import Landscape from "./components2/UI/Landscape/Landscape";
import {
  EnterFullScreenButton,
  ExitFullScreenButton,
} from "./components2/Controls/FullScreen";
import CampaignFinal from "./components2/Experience/CampaignFinal";
import UIfinal from "./components2/UI/UIfinal";
import useAudioContainer from "./utils2/useAudioContainer";
import Card from "../components/Card";
import { useState } from "react";
import AddtoCart from "./AddtoCart";

export default function Gabar({ audioFile, videoFile, productData }) {
  const handle = useFullScreenHandle();
  const { isProductCartOpen, productDatas, closeProductCart } =
    useAudioContainer();

  const [varID, setVarID] = useState("");
  const [productCounter, setProductCounter] = useState(0);
  const [checkoutURL, setCheckoutURL] = useState("");

  const handlePopup = () => {
    closeProductCart();
  };

  const handleID = (data) => {
    setVarID(data);
  };

  const handleProductCount = (data) => {
    setProductCounter(productCounter + data);
  };

  const handleCheckout = (data) => {
    setCheckoutURL(data);
  };

  return (
    <>
      <div className="App">
        <div className="ReactFullScreen">
          <EnterFullScreenButton handle={handle} />
          <FullScreen handle={handle}>
            <div>
              <ExitFullScreenButton handle={handle} />
              <UIfinal musicUrl={audioFile} finalScreen={true} />
              <CampaignFinal videoUrl={videoFile} productData={productData} />
            </div>
          </FullScreen>
        </div>
      </div>
      <Loading />
      {/* <Landscape /> */}
      {isProductCartOpen && (
        <AddtoCart data={productDatas} closePopup={handlePopup} />
      )}
    </>
  );
}
