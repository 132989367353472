import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Product_Click({ name, onClick, onDoubleClick, position }) {
  const { nodes, materials } = useGLTF("/assets/models/product_click.glb");
  return (
    <group dispose={null}>
      <mesh
        name={name}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        geometry={nodes.Cylinder.geometry}
        material={nodes.Cylinder.material}
        position={position}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/product_click.glb");
