import { create } from "zustand";

const useAudioContainer = create((set) => ({
  isProductCartOpen: false,
  audioUrl: null,
  productDatas: {},
  playAudio: false, 
  openProductCartWithAudio: (url, productVal) => set({ isProductCartOpen: true, audioUrl: url, productDatas: productVal }),
  closeProductCart: () => set({ isProductCartOpen: false, audioUrl: null, productDatas: {} }),
  triggerAudioPlayback: () => set({ playAudio: true }), 
  stopAudioPlayback: () => set({ playAudio: false }) 
}));

export default useAudioContainer;