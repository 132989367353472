import React, { useState } from "react";
import useModelStore from '../store';

function Box({ productData, onApply }) {
  const [formValues, setFormValues] = useState([]);
  const [name,changeName] = useState('');
  const [location,setLocation] = useState('location0');
  const setModelPosition = useModelStore((state) => state.setModelPosition);

  // Handle product selection
  const handleProductChange = (product_uid) => {
    const selectedProduct = productData.find(product => product.product_uid === parseInt(product_uid));
    console.log(selectedProduct);    
    setFormValues((prevValues) => {
      const existingProduct = prevValues.find(
        (item) => item.product_uid === product_uid
      );
      if (!existingProduct) {
        return [...prevValues, { product_uid, productPosition: "", product_id: selectedProduct.product_id }];
      }
      return prevValues; 
    });
    changeName(product_uid);
  };


  

  // Handle position selection
  const handlePositionChange = (position) => {
    setFormValues((prevValues) =>
      prevValues.map((item) =>
        item.product_uid === selectedProduct
          ? { ...item, productPosition: position }
          : item
      )
    );
    setLocation(position);
  };

  // Handle product selection from dropdown
  const [selectedProduct, setSelectedProduct] = useState("");

  const getPositionFromLocation = (location) => {
    switch (location) {
      case "location0":
        return [1000,1000,1000];
      case "location1":
        return [-4.891337813128718, 2, -5.720227980977997];
      case "location2":
        return [1.9916866740908432, 2, 13.23984951053118];
      case "location3":
        return [-5.953889676150517, 2, 21.4005676390457];
      case "location4":
        return [-30.49039677915537, 2, 13.560054539298182];
      case "location5":
        return [-26.521714223481858, 2, -7.335591221166235];
      default:
        return [0, 0, 0]; // Default position if no location is matched
    }
  };

  const handleSubmit = () => {
    onApply(formValues);     
    if(name.length && location !== 'location0'){
      setModelPosition(name, getPositionFromLocation(location));
    }    
    setLocation('location0')
  };

  const handleData =() =>{
    console.log(formValues);
    
  }

  return (
    <div
      style={{
        position: "absolute",
        bottom: "0",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        gap: "10px",
        padding: "13px",
        borderRadius: "5px",
        background: "#fff",
      }}
    >
      {/* Product Selection */}
      <select
        value={selectedProduct}
        onChange={(e) => {
          setSelectedProduct(e.target.value);
          handleProductChange(e.target.value);
        }}
      >
        <option value="">--Select Product--</option>
      
        {productData.map((product) => (
          <option
            key={product.product_uid}
            value={product.product_uid}
          >
            {product.name}
          </option>

          
        ))}
      </select>

      {/* Position Selection */}
      <select
        value={
          formValues.find((item) => item.product_uid === selectedProduct)
            ?.productPosition || "location0"
        }
        onChange={(e) => handlePositionChange(e.target.value)}
      >
        <option value="location0">--Select Position--</option>
        <option value="location1">Location 1</option>
        <option value="location2">Location 2</option>
        <option value="location3">Location 3</option>
        <option value="location4">Location 4</option>
        <option value="location5">Location 5</option>
      </select>

      <button onClick={handleSubmit} style={{cursor:'pointer', padding: '0px 13px', backgroundColor: '#fff', borderRadius: '2px', borderWidth: '1px'}}>Apply</button>
      <button onClick={handleData} style={{color:'unset', borderColor:'unset', borderRadius: '2px', padding: '0px 13px' , backgroundColor: '#eee'}}>Save</button>
    </div>
  );
}

export default Box;
