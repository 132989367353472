import { useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import { CameraControls } from "@react-three/drei";
import useStore from ".././../utils2/3dstore";
import "../../utils2/3dstore";
import { useRef } from "react";
import { degToRad } from "three/src/math/MathUtils.js";

let upRotate;
let sideRotate;
export const CustomCameraControls = () => {
  const {
    isMovingToTarget,
    isMovingWithJoystick,
    joystickTurn,
    isRotateCamera,
    joystickRotation,
    isEntryAnimationDone,
    setRotateCamera,
    isPanEnabled,
    setPanEnabled,
    setCameraPanning,
  } = useStore();

  const cameraControlsRef = useRef();
  useEffect(() => {
    cameraControlsRef.current.moveTo(-16, 8, -48, true);
    cameraControlsRef.current.rotate(Math.PI, 0.056929270445428067, true);
    cameraControlsRef.current.azimuthRotateSpeed = -0.3;
    cameraControlsRef.current.polarRotateSpeed = -0.3;
    cameraControlsRef.current.minPolarAngle = degToRad(45);
  }, []);

  useEffect(() => {
    if (isMovingWithJoystick) setPanEnabled(false);
    if (!isMovingWithJoystick && isEntryAnimationDone && !isMovingToTarget)
      setPanEnabled(true);
  }, [isMovingWithJoystick, isEntryAnimationDone, isMovingToTarget]);

  useFrame((state, delta) => {
    setCameraPanning(cameraControlsRef.current._isUserControllingRotate);
    upRotate = cameraControlsRef.current.camera.rotation.x;
    sideRotate = cameraControlsRef.current.camera.rotation.y;
    // console.log(upRotate)
    if (isRotateCamera) {
      if (sideRotate >= 0 && sideRotate < 0.15)
        cameraControlsRef.current.rotate(degToRad(-0.06 * 70 * delta), 0, true);
      else setRotateCamera(false);
    }

    if (isMovingWithJoystick) {
      joystickTurn < 0
        ? cameraControlsRef.current.rotate(
            degToRad(joystickRotation * 25 * delta),
            0,
            true
          )
        : cameraControlsRef.current.rotate(
            degToRad(-joystickRotation * 25 * delta),
            0,
            true
          );
    }
    cameraControlsRef.current._isUserControllingRotate = false;
    state.camera.updateProjectionMatrix();
    cameraControlsRef.current.update(delta);
  });

  return <CameraControls enabled={isPanEnabled} ref={cameraControlsRef} />;
};
