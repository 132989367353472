import "../../Home2.css";
import { Environment } from "@react-three/drei";
import { Physics, RigidBody } from "@react-three/rapier";
import { Molecules } from "./Molecules";
import { HDRI_HIGH, HDRI_LOW } from "./HDRI";
import { Ground } from "./Ground";
import { Player } from "../Controls/Player";
import { CustomCameraControls } from "../Controls/CustomCameraControls";
import { Canvas } from "@react-three/fiber";
import { Joystick } from "../Controls/Joystick";
import { EntryCollider } from "./EntryCollider";
import { degToRad } from "three/src/math/MathUtils.js";
import { Suspense, useEffect, useRef, useState } from "react";
import { Building_Products } from "./Building_Products";
import { useNavigate } from "react-router-dom";
import Molecules1 from "./Molecule1";
import { useSelector } from "react-redux";
import { fetchData } from "../../../service/http.service";
import ApiConfig from "../../../config/ApiConfig";


export default function  CampaignFinal({videoUrl, productData}) {
  return (
    <>
      <div>
        <Joystick />
        <div className="canvas3d">
          <Canvas
            camera={{
              fov: 65,
            }}
          >
            <Suspense fallback={<HDRI_LOW />}>
              <HDRI_HIGH />
            </Suspense>            
            <ambientLight intensity={1.5} />
            <Environment background={false} preset="city" />
            <CustomCameraControls />
            <Physics gravity={[0, -20, 0]}>
              <group rotation={[0, degToRad(175), 0]} position={[-28.5, -1, 0]}>
                <Ground />
                <EntryCollider />
                <Player />
                <Building_Products videoUrl={videoUrl}  />
              </group>
              <group rotation={[0, degToRad(180), 0]} position={[-28.5, 0, 0]}>
                {/* <Molecules /> */}
              </group>
              <RigidBody type="fixed">    
                {productData  && <Molecules1 productData={productData} showProduct={true} hasToken={false}/> }
              </RigidBody>
            </Physics>
          </Canvas>
        </div>
      </div>
    </>
  );
}