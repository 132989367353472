import React, { useEffect, useState } from "react";
import "./Card.scss";
import CongratulationsBgImage from "../assests/images/rectangle47.png";
import { ReactComponent as ViewLiveIcon } from "../assests/svg/Maximize.svg";
import { ReactComponent as ArrowIcon } from "../assests/svg/arrow.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import "./Congratulation.scss";

export default function Congratulations() {
  const [uuid, setUuid] = useState();
  const [loading, setLoading] = useState(false);
  const [imageURL,setImageURL] = useState(null);
  const [paymentDate,setPaymentDate] = useState(null);
  const [retainTrai,setRetainTrai] =useState(null);
  const [nextPaymentDate, setNextpaymentDate] = useState(null);
  const navigate = useNavigate();
  const company = useSelector((state) => state.user.company);
  const first_name = useSelector((state) => state.user.first_name);
  const token = useSelector((state) => state.token);

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(false);
      const response = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response?.statusCode === 401 || response?.statusCode === 500) {

        navigate("/login");
      } else if (response?.data.statusCode === 200) {
        setUuid(response?.data.data.brand_param);
        setImageURL(response?.data.data.env_template_master.env_thumbnail_url);
        setPaymentDate(response?.data.data.payment_date);
        setNextpaymentDate(response?.data.data.next_payment_date);
        setRetainTrai(response?.data.data.retain_trail)
      }
      setLoading(true);
    };
    getEnvDetails();
  }, []);

  function navigateEnv() {
    if (uuid !== null) {
      window.open(`/${company}/playAround/${uuid}`, "_blank");

    } else {
      navigate(`/dashboard`);
    }
  }

  const getCurrentDate = () => {
    const today = new Date();
    
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(today.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  };
  
  const currentDate = getCurrentDate();
  const differenceInMilliseconds = new Date(nextPaymentDate) - new Date(currentDate);
  return (
    <>
      <div className="congratulations-heading">
        <div className="congrats-heading">
          <h2>Congratulations!</h2>
          <p>Your virtual store is ready now.</p>
        </div>
        <div className="congratulations-container">
          <div className="image-wrapper">
            {loading &&(
            differenceInMilliseconds / (1000 * 60 * 60 * 24) >= 0 ? 
            <p className="demo-text">Demo Ends in : {differenceInMilliseconds / (1000 * 60 * 60 * 24)} Days</p>
            :
            (retainTrai )?
            <div>
              <p className="demo-text">Hi {first_name} <br></br>Congratulation! Your demo period has been extended. <br></br>If you require further assistance, please get in touch with support@onewayx.com.</p>
              <p className="demo-text">ONEWAYX Team </p>
            </div>
            :
            <div>
            <p className="demo-text">Hi {first_name} <br></br>Your demo period with ONEWAYX has expired. <br></br>Please get in touch with support©onewayx.com to reactivate your account.   </p>
            <p className="demo-text">ONEWAYX Team </p>
          </div>)
            }
            {loading? <img src={imageURL} width="100%" alt="coming soon" /> :
            <img src={CongratulationsBgImage} width="100%" alt="coming soon" />
            }

            <div className="btn-container">
              <button
                className="view-live"
                type="button"
                style={{ cursor: "pointer" }}
                disabled={!loading}
                onClick={() => navigateEnv()}
              >
                {loading ? "View Live Environment" : "loading"}{" "}
                <span>
                  <ViewLiveIcon />
                </span>
              </button>
              <button
                className="go-live"
                style={{ cursor: "pointer", display: "none" }}
              >
                Go Live{" "}
                <span>
                  <ArrowIcon />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
