import React from 'react'
import "./trademark.css"

function TradeMark() {
  return (
    <>
    <div className="trade-mark">
        <img className="trade-mark-img" src="/assets/images/poweredby_1.svg" />
      </div>
    </>
    
  )
}

export default TradeMark