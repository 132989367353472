import { useRef, useEffect, useState } from "react";
import "./sound.css";
import useSoundStore from "./soundStore";
import useGameStore from "../../Game/utils/gameStore";

const Player = () => {
  const audioElement = useRef();
  const countDownTimer = useRef();
  const correctAnswer = useRef();
  const wrongAnswer = useRef();
  const quizEnd = useRef();
  const {
    isPlaying,
    isMuted,
    isCountDown,
    playCorrectAnswer,
    playWrongAnswer,
    playQuizEnd,
  } = useSoundStore();
  const { isGamePlaying } = useGameStore();

  useEffect(() => {
    if (isPlaying) {
      audioElement.current.play();
    } else {
      audioElement.current.pause();
    }
    if (isCountDown) {
      audioElement.current.volume = 10 / 100;
    } else {
      audioElement.current.volume = 50 / 100;
    }
    countDownTimer.current.volume = 50 / 100;
  });

  useEffect(() => {
    if (isGamePlaying || isCountDown) {
      audioElement.current.volume = 0.1; // 10% volume
    } else {
      audioElement.current.volume = 0.5; // 50% volume
    }
  }, [isGamePlaying]);

  useEffect(() => {
    if (isCountDown) {
      countDownTimer.current.play();
    } else {
      countDownTimer.current.pause();
      countDownTimer.current.currentTime = 0;
    }
    if (playCorrectAnswer) {
      correctAnswer.current.play();
    } else {
      correctAnswer.current.pause();
      correctAnswer.current.currentTime = 0;
    }
    if (playWrongAnswer) {
      wrongAnswer.current.play();
    } else {
      wrongAnswer.current.pause();
      wrongAnswer.current.currentTime = 0;
    }
    // if (playQuizEnd) {
    //   quizEnd.current.play();
    // } else {
    //   quizEnd.current.pause();
    //   quizEnd.current.currentTime = 0;
    // }
  }, [isCountDown, playCorrectAnswer, playWrongAnswer]);

  useEffect(() => {
    if (isMuted) {
      audioElement.current.muted = true;
      countDownTimer.current.muted = true;
      correctAnswer.current.muted = true;
      wrongAnswer.current.muted = true;
      quizEnd.current.muted = true;
    } else {
      audioElement.current.muted = false;
      countDownTimer.current.muted = false;
      correctAnswer.current.muted = false;
      wrongAnswer.current.muted = false;
      quizEnd.current.muted = false;
    }
  }, [isMuted]);
  return (
    <div className="sound-control">
      <audio loop ref={audioElement}>
        <source src="/assets/audio/sound-mp3_0.mp3" type="audio/mpeg" />
        <source src="/assets/audio/sound-aac.aac" type="audio/aac" />
        <source src="/assets/audio/sound-ogg.ogg" type="audio/ogg" />
      </audio>
      <audio ref={countDownTimer}>
        <source src="/assets/audio/last-10-seconds.mp3" type="audio/mpeg" />
      </audio>
      <audio ref={correctAnswer}>
        <source src="/assets/audio/correct-answer.mp3" type="audio/mpeg" />
      </audio>
      <audio ref={wrongAnswer}>
        <source src="/assets/audio/wrong-answer.mp3" type="audio/mpeg" />
      </audio>
      <audio ref={quizEnd}>
        <source src="/assets/audio/quiz-end.mp3" type="audio/mpeg" />
      </audio>
    </div>
  );
};

const SoundOnOff = () => {
  const { isMuted, setMuted } = useSoundStore();
  const [isMuteVisible, setMuteVisibity] = useState(0);
  const [isUnmuteVisible, setUnMuteVisibility] = useState(1);

  useEffect(() => {
    if (isMuted) {
      setUnMuteVisibility(0);
      setMuteVisibity(1);
    } else {
      setUnMuteVisibility(1);
      setMuteVisibity(0);
    }
  }, [isMuted]);
  return (
    <div
      className="music_icon"
      onClick={() => {
        setMuted(!isMuted);
      }}
    >
      <img
        className="sound-icon-img hide-selection"
        style={{
          fontSize: 30,
          opacity: isUnmuteVisible,
          position: "absolute",
          zIndex: 10,
        }}
        src="/assets/images/audible.png"
      />
      <img
        className="sound-icon-img hide-selection"
        style={{
          fontSize: 30,
          opacity: isMuteVisible,
          position: "absolute",
          zIndex: 11,
        }}
        src="/assets/images/muted.png"
      />
    </div>
  );
};

export { SoundOnOff, Player };
