import React, { useEffect, useState } from "react";
import CongratulationsBgImage from "../assests/images/rectangle47.png";
import { ReactComponent as ViewLiveIcon } from "../assests/svg/Maximize.svg";
import { ReactComponent as ArrowIcon } from "../assests/svg/arrow.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import "../components/Congratulation.scss";
import "../components/Card.scss";
import "./Enviroment.scss";

export default function Enviroment() {
  const [uuid, setUuid] = useState();
  const [selectedEnv,setSelectedEnv] = useState();
  const [loading, setLoading] = useState(false);
  const [imageURL,setImageURL] = useState(null);
  const navigate = useNavigate();
  const company = useSelector((state) => state.user.company);
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user.first_name);

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(false);
      const response = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response.statusCode === 401 || response.statusCode === 500) {
        navigate("/login");
      } else if (response.data.statusCode === 200) {
        setUuid(response.data.data.brand_param);
        setImageURL(response?.data.data.env_template_master.env_thumbnail_url);
      }

      const response2 = await fetchData(
        `${ApiConfig.info}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response2?.statusCode === 401 ||response2?.statusCode === 500) {
        navigate("/login");
      }
      else if(response2.data.statusCode === 200){
        setSelectedEnv(response2.data.data.envDetail.env_template_id);
      }
      setLoading(true);
    };
    getEnvDetails();
  }, []);

  function navigateEnv() {
    if (uuid !== null) {
      window.open(`/${company}/playAround/${uuid}`, "_blank");
    } else {
      navigate(`/dashboard`);
    }
  }
  return (
    <div className="congratulations-heading enviroment">
      <div className="congrats-heading">
        <h2>Welcome back, {user}</h2>
        <p>Customise your dashboard!</p>
      </div>
      <div className="congratulations-container">
        <div className="image-wrapper">
        {loading? <img src={imageURL} width="100%" alt="coming soon" /> :
          <img src={CongratulationsBgImage} width="100%" alt="coming soon" />
        }
          <div className="btn-container">
            <button
              className="view-live"
              type="button"
              disabled={!loading}
              onClick={() => navigateEnv()}
            >
              {loading ? "View Live Environment" : "loading"}{" "}
              <span>
                <ViewLiveIcon />
              </span>
            </button>
           
            <button className="go-live"  disabled={!loading} onClick={() => {
                navigate(`/playarea/${selectedEnv}`)
              }}>
            Edit Environment{" "}
              <span>
                <ArrowIcon />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
