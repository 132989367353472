import "../../Home2.css";
import { Environment } from "@react-three/drei";
import { Physics } from "@react-three/rapier";
import { Molecules } from "./Molecules";
import { HDRI_HIGH, HDRI_LOW } from "./HDRI";
import { Ground } from "./Ground";
import { Player } from "../Controls/Player";
import { CustomCameraControls } from "../Controls/CustomCameraControls";
import { Canvas } from "@react-three/fiber";
import { Joystick } from "../Controls/Joystick";
import { EntryCollider } from "./EntryCollider";
import { degToRad } from "three/src/math/MathUtils.js";
import { Suspense, useEffect, useRef, useState } from "react";
import { Building_Products } from "./Building_Products";
import Molecules1 from "./Molecule1";
import { RigidBody } from "@react-three/rapier";
import Box from "./Box";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApiConfig from "../../../config/ApiConfig";
import { fetchData } from "../../../service/http.service";



function Campaign({videoUrl}) {
  const token = useSelector((state) => state.token);
  const [loading,setLoading] =  useState(false);
  const navigate = useNavigate(); 
  const [productData,getProductData] = useState([]);
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(false);
      const response = await fetchData( 
      `${ApiConfig.productList}?page=1&limit=100`,
        setLoading,
        "get",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response.statusCode === 401 || response.statusCode === 500) {
        navigate('/login');
      }
      else if (response.data.statusCode === 200) {      
        getProductData(response.data.data);
      }      
      setLoading(true);
    };
    getEnvDetails();
  }, []);



// const handleVideoUpload = (event) => {
//     const file = event.target.files[0];
//     if (file && file.type === "video/mp4") {
//       setSelectedFile(file); 
//     }
//   };

//   // Handle form submission
//   const handleSubmit = () => {
//     if (selectedFile) {
//       const newVideoUrl = URL.createObjectURL(selectedFile);
//       setVideoUrl(newVideoUrl); 
//     }
//   };


  return (
    <>
      {/* <div style={{position:'absolute', zIndex: '2', backgroundColor: '#fff', padding:'13px', bottom: '10px', left: '50%', transform:'translateX(-50%)'}}>
        <input type="file" accept="video/mp4" onChange={handleVideoUpload} />
        <button onClick={handleSubmit}>Submit</button>
        <button onClick={() => {
            setVideoUrl(null);
            setSelectedFile(null);
        }}>clear</button>
      </div> */}
      <div>
        <Joystick />
        <div className="canvas3d">
          <Canvas
            camera={{
              fov: 65,
            }}
          >
            <Suspense fallback={<HDRI_LOW />}>
              <HDRI_HIGH />
            </Suspense>
            <ambientLight intensity={1.5} />
            <Environment background={false} preset="city" />
            <CustomCameraControls />
            <Physics gravity={[0, -20, 0]}>
              <group rotation={[0, degToRad(175), 0]} position={[-28.5, -1, 0]}>
                <Ground />
                <EntryCollider />
                <Player />
                <Building_Products videoUrl={videoUrl} />
              </group>
              <group rotation={[0, degToRad(180), 0]} position={[-28.5, 0, 0]}>
                {/* <Molecules /> */}
              </group>
              <RigidBody type="fixed">                
                {productData  && <Molecules1 productData={productData}/>}
              </RigidBody>
            </Physics>
          </Canvas>
        </div>
        {/* {productData && <Box onDropImage={handleDropImage}  onApply={handleApply} productData={productData}/>} */}
      </div>
    </>
  );
}

export default Campaign;
