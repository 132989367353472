import React, { useEffect, useState } from "react";
import useGameStore from "../utils/gameStore";
import { useRandomQuestions} from "../utils/useRandomQuestions";
import { GameBoard } from "./GameBoard";

export const GameBoards = () => {
  const {
    handleShowGameWaitPopup,
    nextPlayableTime,
    handleActiveBoardIndex,
    activeBoardIndex,
    handleShowGameOnEnterPopup,
    isGameLocked,
    handleGameLock,
    flashIndex,
    isGameplaying,
    setSelectedRandomQuestions,
    selectedRandomQuestions,
  } = useGameStore();
  const [activeIndex, setActiveIndex] = useState(null);
  const [switched, setSwitchIndex] = useState(null);

  const handleClick = (index) => {
    if (new Date() >= nextPlayableTime) {
      if (isGameLocked) {
        handleGameLock(false);
        handleShowGameOnEnterPopup(true);
        switchIndex(null);
        setActiveIndex(index);
        handleActiveBoardIndex(index);
        return;
      }

      switchIndex(index);
      if (flashIndex === index) {
        setActiveIndex(index);
      } else {
        setActiveIndex(index);
      }
      handleActiveBoardIndex(index);
    } else handleShowGameWaitPopup(true);
  };

  useEffect(() => {
    setSelectedRandomQuestions(useRandomQuestions());
  }, []);

  const switchIndex = (index) => {
    console.log("ACTIVATE_BOARD_INDEX: ", [
      activeBoardIndex !== null,
      activeBoardIndex !== index,
      isGameplaying,
    ]);
    if (
      activeBoardIndex !== null &&
      activeBoardIndex !== index &&
      !isGameLocked
    ) {
      setSwitchIndex(activeBoardIndex);
    }
  };

  const time = new Date();
  time.setSeconds(time.getSeconds() + 600 * 1000);

  return (
    <>
      {selectedRandomQuestions.length > 0 &&
        selectedRandomQuestions.map((questionData, index) => (
          <GameBoard
            key={index}
            id={index}
            position={questionData.position}
            rotation={questionData.rotation}
            options={questionData.options}
            question={questionData.question}
            answer={questionData.correctAnswer}
            active={activeIndex === index}
            switched={switched === index}
            onActivateClick={() => handleClick(index)}
            expiryTimestamp={time}
          />
        ))}
    </>
  );
};
