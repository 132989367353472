import React, { useEffect, useState } from "react";
import Launching from './Launching';
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


function Launch() {
    const token = useSelector((state) => state.token);
    const [loading, setLoading] = useState(false);
    const [envData, setEnvData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const getEnvDetails = async () => {
          setLoading(false);
          const response = await fetchData(
            `${ApiConfig.env}`,
            setLoading,
            "post",
            {},
            { authorization: `Bearer ${token}` }
          );
          if (response.status === 401 ||response.status === 500) {
            navigate("/login");
          }
          if (response.data.statusCode === 200) {
            var color;
            if (response.data.data.selected_colour === "White") {
              color = "#FFFFFF";
            } else if (response.data.data.selected_colour === "Cappuccino") {
              color = "#A59385";
            }
            else if (response.data.data.selected_colour === "Red") {
              color = "#FF0000";
            } 
            else if (response.data.data.selected_colour === "Gold") {
              color = "#FFD700";
            }
            else if (response.data.data.selected_colour === "Rose Pink") {
              color = "#F5D7D5";
            }  else {
              color = "";
            }
            setEnvData({
              logo: response.data.data.logo_image_url,
              campaign: response.data.data.campaign_link_url,
              campaignType: response.data.data.campaign_type,
              light: response.data.data.selected_lighting,
              color: color,
              texture: response.data.data.selected_texture,
              backgroundEnv:
                response.data.data.env_template_master.env_thumbnail_url,
              env: response.data.data.env_template_master.env_buildfile_url,
              templateNumber: response.data.data.env_template_master.env_template_id,
            });
          }
          setLoading(true);
        };
        getEnvDetails();
      }, []);

  return (
    <>
        {loading ? <Launching envdetail={envData} /> : <p></p>}
    </>
  )
}

export default Launch
